import React from 'react';
import {
  Route,
  Routes,
  BrowserRouter as Router,
} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import { Provider as LanguageProvider } from './context/LanguageContext';
import { Provider as AuthProvider } from './context/AuthContext';
import { Provider as CategoryProvider } from './context/CategoryContext';
import { Provider as NewsProvider } from './context/NewsContext';
import { Provider as BannerProvider } from './context/BannerContext';
import { Provider as OrderProvider } from './context/OrderContext';
import { Provider as PackagesProvider } from './context/PackagesContext';
import { Provider as BlogsProvider } from './context/BlogsContext';
import { Provider as PopupProvider } from './context/PopupContext';
import { Provider as NotificationsProvider } from './context/NotificationsContext';
import HomeScreen from './screens/HomeScreen';
import BookingScreen from './screens/BookingScreen';
import LatestNews from './screens/LatestNews';
import NewsDetails from './screens/NewsDetails';
import PackagesScreen from './screens/PackagesScreen';
import LocationScreen from './screens/LocationScreen';
import ProductScreen from './screens/ProductScreen';
import TestsScreen from './screens/TestsScreen';
import SearchResults from './screens/SearchResults';
import ContactScreen from './screens/ContactScreen';
import AboutScreen from './screens/AboutScreen';
import LoginScreen from './screens/LoginScreen';
import VisionScreen from './screens/VisionScreen';
import PrivacyScreen from './screens/PrivacyScreen';
import PrivacyArabicScreen from './screens/PrivacyArabicScreen';
import AdminLogin from './admin/AdminLogin';
import AdminVideo from './admin/AdminVideo';

import AddNews from './admin/add/AddNews';
import AddProducts from './admin/add/AddProducts';
import AddPackages from './admin/add/AddPackages';
import AddBlogs from './admin/add/AddBlogs';

import EditNews from './admin/edit/EditNews';
import EditProducts from './admin/edit/EditProducts';
import EditPackages from './admin/edit/EditPackages';
import EditBlogs from './admin/edit/EditBlogs';

import DeleteNews from './admin/delete/DeleteNews';
import DeleteProducts from './admin/delete/DeleteProducts';
import DeletePackages from './admin/delete/DeletePackages';
import DeleteBlogs from './admin/delete/DeleteBlogs';

import Banner from './admin/Banner';
import Orders from './admin/Orders';
import Notifications from './admin/Notifications';
import PopUp from './admin/PopUp';
import Customers from './admin/Customers';
import Sidebar from './admin/components/Sidebar';
import PackageDetailsScreen from './screens/PackageDetailsScreen';
import BlogsScreen from './screens/BlogsScreen';
import BlogDetailsScreen from './screens/BlogDetailsScreen';

function App() {
  const THEME = createTheme({
    typography: {
      fontFamily: 'tajawal'
    }
  });
  return (
    <ThemeProvider theme={THEME}>
      <CategoryProvider>
        <NewsProvider>
          <BlogsProvider>
            <PackagesProvider>
              <OrderProvider>
                <BannerProvider>
                  <PopupProvider>
                    <AuthProvider>
                      <NotificationsProvider>
                        <LanguageProvider>
                          <Router>
                            <Routes>
                              <Route path="/" element={<HomeScreen />} />
                              <Route path="/booking" element={<BookingScreen />} />
                              <Route path="/latest_news" element={<LatestNews />} />
                              <Route path="/news_details" element={<NewsDetails />} />
                              <Route path="/package_details" element={<PackageDetailsScreen />} />
                              <Route path="/packages" element={<PackagesScreen />} />
                              <Route path="/blogs" element={<BlogsScreen />} />
                              <Route path="/blog_details" element={<BlogDetailsScreen />} />
                              <Route path="/locations" element={<LocationScreen />} />
                              <Route path="/product" element={<ProductScreen />} />
                              <Route path="/tests" element={<TestsScreen />} />
                              <Route path="/search" element={<SearchResults />} />
                              <Route path="/contact" element={<ContactScreen />} />
                              <Route path="/about" element={<AboutScreen />} />
                              <Route path="/results" element={<LoginScreen />} />
                              <Route path="/vision" element={<VisionScreen />} />
                              <Route path="/privacy" element={<PrivacyScreen />} />
                              <Route path="/arabic-privacy" element={<PrivacyArabicScreen />} />
                            </Routes>
                            <Routes>
                              <Route path="/adminarea" element={<AdminLogin />} />
                              <Route element={<Sidebar />}>
                                <Route path="/add_news" element={<AddNews />} />
                                <Route path="/add_products" element={<AddProducts />} />
                                <Route path="/add_packages" element={<AddPackages />} />
                                <Route path="/add_blogs" element={<AddBlogs />} />
                                <Route path="/edit_packages" element={<EditPackages />} />
                                <Route path="/edit_news" element={<EditNews />} />
                                <Route path="/edit_products" element={<EditProducts />} />
                                <Route path="/edit_blogs" element={<EditBlogs />} />
                                <Route path="/delete_news" element={<DeleteNews />} />
                                <Route path="/delete_products" element={<DeleteProducts />} />
                                <Route path="/delete_packages" element={<DeletePackages />} />
                                <Route path="/delete_blogs" element={<DeleteBlogs />} />
                                <Route path="/banner" element={<Banner />} />
                                <Route path="/orders" element={<Orders />} />
                                <Route path="/notifications" element={<Notifications />} />
                                <Route path="/pop-up" element={<PopUp />} />
                                <Route path="/customers" element={<Customers />} />
                                <Route path="/admin_video" element={<AdminVideo />} />
                              </Route>
                            </Routes>
                          </Router>
                        </LanguageProvider>
                      </NotificationsProvider>
                    </AuthProvider>
                  </PopupProvider>
                </BannerProvider>
              </OrderProvider>
            </PackagesProvider>
          </BlogsProvider>
        </NewsProvider>
      </CategoryProvider>
    </ThemeProvider>
  );
}

export default App;
