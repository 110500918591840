import React, { useContext } from 'react';
import { Box, Container, Typography, makeStyles, Divider } from '@material-ui/core';
import NavBar from '../components/Navbar';
import { Context as LanguageContext } from '../context/LanguageContext';
import { appColor, windowHeight, windowWidth } from '../common';
import { isMobileOnly } from 'react-device-detect';
import Footer from '../components/Footer';

const LoginScreen = () => {
  const {
    state: { language },
  } = useContext(LanguageContext);

  const props = {
    language,
  };

  const classes = useStyles(props);
  return (
    <Box>
      <NavBar />
      <div >
        <Container style={{ direction: language === 'en' ? 'ltr' : 'rtl', marginTop: '2%', minHeight: windowHeight / 1.5 }}>
          <Typography className={classes.title}>
            {language === 'en' ? 'Your Results' : 'نتائج الفحوصات'}
          </Typography>
          <iframe
            src="https://lab.smartlabsgroup.com/doctors_index.php"
            id="ifPatients"
            title='login'
            style={{ height: 400, overflow: "auto", width: '100%' }} //"border:none; width: 100%; height: 600px;"
          />
        </Container>
        <Footer />
      </div>
    </Box>
  );
};

export const useStyles = makeStyles({
  title: {
    fontSize: '1.7em',
    color: appColor,
  },
})

export default LoginScreen;