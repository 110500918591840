import React, { useEffect, useContext, useState, useRef } from 'react';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Divider
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';
import NavBar from '../components/Navbar';
import { Context as LanguageContext } from '../context/LanguageContext';
import { Context as CategoryContext } from '../context/CategoryContext';
import { appColor, windowHeight, windowWidth } from '../common';
import { isMobile, isMobileOnly } from 'react-device-detect';
import TestsSidebar from '../components/TestsSidebar';

const AWS_URL = 'https://smartlabs-bucket.s3.eu-central-1.amazonaws.com/';

const TestsScreen = () => {
  const [arabicName, setArabicName] = useState('');
  const [englishName, setEnglishName] = useState('');
  const [arabicDescription, setArabicDescription] = useState('');
  const [englishDescription, setEnglishDescription] = useState('');
  const [imageUrl, setImageUrl] = useState(null);
  const [products, setProducts] = useState([]);
  const [categoriesFetched, setCategoriesFetched] = useState(false);

  const firstButtonRef = useRef(null);

  const {
    state: { language },
  } = useContext(LanguageContext);
  const { fetchCategories, fetchAllProducts, searchProducts, state: { categories, allProducts } } = useContext(CategoryContext)

  const props = {
    language
  };

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (firstButtonRef.current) {
      firstButtonRef.current.click();
    }

    async function fetchData() {
      await fetchCategories();
      setCategoriesFetched(true);
    }

    Aos.init({
      duration: 2000
    });

    if (!categoriesFetched)
      fetchData();
  }, [categories]);

  const classes = useStyles(props);
  return (
    <Box>
      <NavBar />
      <Container style={{ marginTop: '5%' }}>
        <Box className={classes.titleContainer}>
          <img
            src={require('../images/book.png')}
            alt='book'
            className={classes.bookImg}
          />
          <Box>
            <Typography className={classes.title}>
              {language === 'en' ? 'Tests' : 'الفحوصات'}
            </Typography>
            <Divider className={classes.divider} />
          </Box>
        </Box>
        <div style={{ direction: language === 'en' ? 'ltr' : 'rtl' }} data-aos="fade-right" className={classes.root}>
          <Grid container>
            <Grid xs={12} item md={4}>
              <Box className={classes.blogsTitlesContainer}>
                <Typography className={classes.blogsTitlesText}>
                  {language === 'en' ? 'Tests Categories' : 'أصناف الفحوصات'}
                </Typography>
                <hr />
                {categories.map((item, index) => {
                  let categoryProducts = allProducts.filter(product => product.categoryId === item.id);
                  const isLast = categories.length - 1 === index;
                  return (
                    <>
                      <Button
                        variant="text"
                        ref={index === 0 ? firstButtonRef : null}
                        className={classes.button}
                        onClick={() => {
                          setArabicName(item.arabicName);
                          setEnglishName(item.englishName);
                          setArabicDescription(item.arabicDescription);
                          setEnglishDescription(item.englishDescription);
                          setImageUrl(AWS_URL + item.imageUrl);
                          setProducts(categoryProducts);
                        }}
                      >
                        <Typography>
                          {language === 'en' ? item.englishName : item.arabicName}
                        </Typography>
                      </Button>
                      {!isLast && <hr />}
                    </>
                  )
                })}
              </Box>
            </Grid>
            <Grid xs={12} item md={8}>
              <Box className={classes.boxContainer} style={{ direction: language === 'en' ? 'ltr' : 'rtl' }}>
                <Typography className={classes.testTitle}>
                  {language === 'en' ? 'Tests' : 'الفحوصات'}
                </Typography>
                <hr />
                {products.map((product, index) => {
                  const isLast = products.length - 1 === index;
                  return (
                    <>
                      <Box className={classes.testContainer}>
                        <Typography className={classes.testsTitles}>
                          {language === 'en' ? product.englishName : product.arabicName}
                        </Typography>
                        <Button
                          variant="contained"
                          className={classes.viewMoreButton}
                          startIcon
                          onClick={() => {
                            navigate('/product', {
                              state: {
                                arabicName: product.arabicName,
                                englishName: product.englishName,
                                arabicDescription: product.arabicDescription,
                                englishDescription: product.englishDescription,
                                imageUrl: AWS_URL + product.imageUrl
                              }
                            })
                          }}
                        >
                          {language === 'en' ? 'View More' : 'أظهر المزيد'}
                        </Button>
                      </Box>
                      {!isLast && <hr />}
                    </>
                  )
                })}

              </Box>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Box>
  );
};

export const useStyles = makeStyles(theme => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '3%'
  },
  bookImg: {
    objectFit: 'contain',
    height: windowHeight / 20,
    marginInline: '2%'
  },
  title: {
    fontSize: '1.7em',
    color: appColor,
  },
  divider: {
    marginTop: '1%',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%'
  },
  boxContainer: {
    boxShadow: theme.shadows[3],
    borderRadius: 10,
    padding: '2%',
    marginBottom: '2%'
  },
  button: {
    "&.active": {
      backgroundColor: 'red',
    },
  },
  testTitle: {
    fontSize: '1.5em',
    color: appColor,
    textAlign: 'center'
  },
  testsTitles: {
    fontSize: '1.1em',
    maxWidth: isMobile ? '65%' : '100%'
  },
  testContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  viewMoreButton: {
    backgroundColor: appColor,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: appColor,
      color: '#FFFFFF'
    }
  },
  blogsTitlesContainer: {
    boxShadow: theme.shadows[3],
    width: isMobile ? '100%' : '85%',
    padding: '3%',
    borderRadius: 10,
    marginBottom: isMobile ? '5%' : '1%'
  },
  blogsTitlesText: {
    fontSize: '1.4em',
  },
}))

export default TestsScreen;