import React, { useState, useContext, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import {
  makeStyles,
  Typography,
  Grid,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { isMobileOnly } from 'react-device-detect';
import { Context as OrderContext } from '../../context/OrderContext';
import CustomModal from './CustomModal';

const windowWidth = window.innerWidth;

const OrderDetails = ({
  id,
  name,
  phoneNumber,
  address,
  dateAndTime,
  appointmentNotes,
  isAppointmentDone
}) => {
  const [notes, setNotes] = useState(appointmentNotes);
  const [isDone, setIsDone] = useState(isAppointmentDone);
  const [modalText, setModalText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    updateNotes,
    updateStatus
  } = useContext(OrderContext);

  const classes = useStyles();

  const handleSubmitNote = async (id) => {
    setLoading(true);

    const res = await updateNotes(id, notes);

    if (res.error) {
      setModalText('There is a problem in updating order notes');
    } else {
      setModalText('Order notes updated successfully');
    }

    setShowConfirmButton(false);
    setModalVisible(true);
    setLoading(false);
  };

  const handleSubmitState = async event => {
    setLoading(true);
    setIsDone(event.target.checked);

    const res = await updateStatus(id, event.target.checked);

    if (res.error) {
      setModalText('There is a problem in updating order status');
      setShowConfirmButton(false);
      setModalVisible(true);
    }

    setLoading(false);
  }

  return (
    <>
      <Box>
        <Accordion className={classes.accordionContainer}>
          <AccordionSummary
            expandIcon={<ExpandMore className={classes.headerText} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ backgroundColor: isDone ? '#34B233' : '#1b2551' }}
          >
            <Grid md={6}>
              <Typography className={classes.headerText}>
                Client Name: {name}
              </Typography>
            </Grid>
            <Grid md={6}>
              <Typography className={classes.headerText}>
                Status: {isDone ? 'Done' : 'Pending'}
              </Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item md={3} xs={12}>
                <Typography className={classes.details}>
                  <span style={{ fontWeight: 'bold' }}>Date: </span>
                  {dateAndTime.toLocaleString().split(',')[0]}
                </Typography>
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography className={classes.details}>
                  <span style={{ fontWeight: 'bold' }}>
                    Phone Number:
                  </span>{' '}
                  {phoneNumber}
                </Typography>
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography className={classes.details}>
                  <span style={{ fontWeight: 'bold' }}>Address:</span>{' '}
                  {address}
                </Typography>
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography className={classes.details}>
                  <span style={{ fontWeight: 'bold' }}>Time:</span>{' '}
                  {dateAndTime.toLocaleString().split(',')[1]}
                </Typography>
              </Grid>
              <Grid item md={11}>
                <TextField
                  value={notes}
                  onChange={e => setNotes(e.target.value)}
                  placeholder={"Put your notes here..."}
                  variant="outlined"
                  fullWidth
                  multiline
                  //minRows={3}
                  InputProps={{
                    endAdornment:
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleSubmitNote(id)}
                      >
                        Submit
                      </Button>
                  }}
                />
              </Grid>
              <Grid style={{ display: 'flex', justifyContent: 'center' }} item md={1}>
                <FormControlLabel
                  control={<Checkbox name="checkedH" color='primary' />}
                  label="Done"
                  onChange={handleSubmitState}
                  checked={isDone}
                  style={{ marginLeft: '1%' }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
      <CustomModal
        title="Warning!"
        content={modalText}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        showConfirmButton={showConfirmButton}
        loading={loading}
      />
    </>
  );
};

const useStyles = makeStyles({
  accordionContainer: {
    marginTop: '2%',
    marginBottom: '2%',
    borderRadius: 15,
    width: '100%'
  },
  headerText: {
    color: 'white'
  },
  details: {
    marginBottom: isMobileOnly ? '8%' : '5%'
  }
});

export default OrderDetails;
