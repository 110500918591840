import { makeStyles } from '@material-ui/core';
import { windowHeight, windowWidth, appColor } from '../common';
import { isMobileOnly, isTablet } from 'react-device-detect';

export const useStyles = makeStyles({
  carouselImage: {
    height: windowHeight / 1.08,
  },
  carouselImageMobile: {
    height: windowHeight / 3,
  },
  carouselimg: {
    objectFit: 'cover',
    //height: windowHeight / 3,
    width: windowWidth
  },
  test: {
    height: isTablet ? '' : windowHeight / 1.5,
    objectFit: 'fill'
  },
  gridStyle: {
    display: 'flex',
    justifyContent: 'center'
  },
  aboutImage: {
    objectFit: 'contain',
    height: windowHeight / 2.5,
  },
  title: {
    marginTop: '3%',
    marginBottom: '2%',
    textAlign: ({ language }) => (language === 'en' ? 'left' : 'right')
    // backgroundColor: props => { console.log(props); return props.color },
    // '&&': {
    //     direction: ({ language }) => (language === 'en' ? 'ltr' : 'rtl'),
    // }
  },
  serviceTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '5%'
  },
  serviceTitle: {
    marginTop: '3%',
    marginBottom: '1%',
    textAlign: 'center'
  },
  serviceImage: {
    objectFit: 'contain',
    height: windowHeight / 2.5,
    alignSelf: 'center',
  },
  content: {
    //direction: ({ language }) => (language === 'en' ? 'ltr' : 'rtl'),
    marginTop: '3%',
    whiteSpace: 'pre-line'
  },
  service: {
    textAlign: 'center',
    color: appColor,
    fontWeight: 'bold',
    marginTop: '5%',
  },
  background: {
    objectFit: 'cover',
    width: '100%',
    height: windowHeight / 2
  },
  appTitle: {
    // position: 'absolute',
    // top: '15%',
    // left: '5%',
    color: appColor,
    fontWeight: 'bold',
    marginTop: '10%'
  },
  appSubTitle: {
    marginTop: '3%',
    color: appColor,
    fontWeight: 'bold'
  },
  appImage: {
    objectFit: 'contain',
    width: isMobileOnly ? windowHeight / 6 : windowHeight / 5,
  },
  googleStore: {
    objectFit: 'contain',
    height: windowHeight / 14,
    position: 'relative',
    bottom: '150%'
  },
  appStore: {
    objectFit: 'contain',
    height: windowHeight / 14,
    marginInline: '5%',
    position: 'relative',
    bottom: '150%'
  },
  appTitleMob: {
    marginTop: '20%',
    left: '5%',
    color: appColor,
    fontWeight: 'bold',
    fontSize: '1.2em'
  },
  appSubTitleMob: {
    marginBottom: '10%',
    left: '5%',
    color: appColor,
    fontWeight: 'bold',
    fontSize: '1.2em'
  },
  appImageMob: {
    objectFit: 'contain',
    height: isMobileOnly ? windowHeight / 4 : windowHeight / 2.5,
    alignSelf: 'center',
    top: '15%',
    marginBottom: '5%',
    right: '10%'
  },
  googleStoreMob: {
    objectFit: 'contain',
    height: windowHeight / 20,
    bottom: '15%',
    //left: '4%',
  },
  appStoreMob: {
    objectFit: 'contain',
    height: windowHeight / 20,
    bottom: '15%',
    //left: '20%'
  },
  newsTitle: {
    marginTop: '3%',
    marginBottom: '2%',
    //direction: ({ language }) => (language === 'en' ? 'ltr' : 'rtl'),
    marginLeft: '3%',
    marginRight: '3%'
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%'
  },
  standImageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardContainer: {
    marginInline: '2%',
    marginBottom: '5%',
    minWidth: '80%'
  },
  media: {
    height: isMobileOnly ? windowHeight / 5 : windowHeight / 3.5,
    objectFit: 'contain',
    width: '100%',
  },
  learnText: {
    background: 'linear-gradient(to right, #1b2551, #acafaf)',
    color: 'white',
    '&:hover': {
      backgroundColor: appColor,
      color: 'white'
    }
  },
  cardContent: {
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
    textAlign: ({ language }) => (language === 'en' ? 'left' : 'right')
  },
  seeMoreButton: {
    background: 'linear-gradient(to right, #1b2551, #acafaf)',
    color: 'white',
    '&:hover': {
      backgroundColor: appColor,
      color: 'white'
    },
    alignSelf: 'center',
  }
});