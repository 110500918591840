import React, { useEffect, useContext, useState } from 'react';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Grid,
  Divider
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';
import NavBar from '../components/Navbar';
import { Context as LanguageContext } from '../context/LanguageContext';
import { Context as PackagesContext } from '../context/PackagesContext';
import { appColor, windowHeight, windowWidth } from '../common';
import { isMobileOnly } from 'react-device-detect';
import Sidebar from '../components/Sidebar';

const AWS_URL = 'https://smartlabs-bucket.s3.eu-central-1.amazonaws.com/';

const PackagesScreen = () => {
  const [arabicTitle, setArabicTitle] = useState('');
  const [englishTitle, setEnglishTitle] = useState('');
  const [arabicDescription, setArabicDescription] = useState('');
  const [englishDescription, setEnglishDescription] = useState('');
  const [imageUrl, setImageUrl] = useState(null);

  const {
    state: { language },
  } = useContext(LanguageContext);
  const { fetchPackages, state: { packages } } = useContext(PackagesContext);

  const props = {
    language
  };

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      await fetchPackages();
    }

    Aos.init({
      duration: 2000
    });

    fetchData();
  }, []);

  const classes = useStyles(props);
  return (
    <Box>
      <NavBar />
      <Container style={{ marginTop: '5%' }}>
        <Box className={classes.titleContainer}>
          <img
            src={require('../images/book.png')}
            alt='book'
            className={classes.bookImg}
          />
          <Box>
            <Typography className={classes.title}>
              {language === 'en' ? 'Packages' : 'الباقات'}
            </Typography>
            <Divider className={classes.divider} />
          </Box>
        </Box>
        {/* <div style={{ direction: language === 'en' ? 'ltr' : 'rtl' }} data-aos="fade-right" className={classes.root}>
          {packages.map(
            ({ imageUrl, arabicTitle, englishTitle, arabicDescription, englishDescription }) => (
              <Grid item sm={6} xs={12} className={classes.standImageContainer}>
                <Card className={classes.cardContainer}>
                  <CardActionArea
                    onClick={() => navigate('/package_details', {
                      state: {
                        imageUrl: AWS_URL + imageUrl, arabicTitle, englishTitle, arabicDescription, englishDescription
                      }
                    })}
                  >
                    <CardMedia
                      className={classes.media}
                      image={AWS_URL + imageUrl}
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="h5" component="h2">
                        {language === 'en' ? englishTitle : arabicTitle}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {language === 'en' ?
                          <div dangerouslySetInnerHTML={{ __html: englishDescription.split('\n')[0].slice(0, 50) + '...' }} />
                          : <div dangerouslySetInnerHTML={{ __html: arabicDescription.split('\n')[0].slice(0, 50) + '...' }} />}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="small"
                      className={classes.learnText}
                      variant="contained"
                      onClick={() => navigate('/news_details', {
                        state: {
                          imageUrl: AWS_URL + imageUrl, arabicTitle, englishTitle, arabicDescription, englishDescription
                        }
                      })}
                    >
                      {language === 'en' ? 'Learn more...' : 'المزيد...'}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            )
          )}
        </div> */}
        <div style={{ direction: language === 'en' ? 'ltr' : 'rtl' }} data-aos="fade-right" className={classes.root}>
          <Grid container>
            <Grid item md={4} xs={12}>
              <Sidebar
                title={language === 'en' ? 'Packages Titles' : 'عناوين الباقات'}
                data={packages}
                setArabicTitle={setArabicTitle}
                setEnglishTitle={setEnglishTitle}
                setArabicDescription={setArabicDescription}
                setEnglishDescription={setEnglishDescription}
                setImageUrl={setImageUrl}
              />
            </Grid>
            <Grid item md={8} xs={12}>
              <Box style={{ direction: language === 'en' ? 'ltr' : 'rtl' }}>
                {imageUrl && <img
                  src={imageUrl}
                  alt="blog_photo"
                  className={classes.img}
                />}
                <Typography style={{ textAlign: language === 'en' ? 'left' : 'right' }} className={classes.title}>
                  {language === 'en' ? englishTitle : arabicTitle}
                </Typography>
                <Divider style={{ marginBottom: '1%' }} />
                <Typography style={{ textAlign: language === 'en' ? 'left' : 'right' }} className={classes.content}>
                  {language === 'en' ? <div dangerouslySetInnerHTML={{ __html: englishDescription }} /> : <div dangerouslySetInnerHTML={{ __html: arabicDescription }} />}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Box>
  );
};

export const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '3%'
  },
  bookImg: {
    objectFit: 'contain',
    height: windowHeight / 20,
    marginInline: '2%'
  },
  title: {
    fontSize: '1.7em',
    color: appColor
  },
  divider: {
    marginTop: '1%',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%'
  },
  standImageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardContainer: {
    marginInline: '2%',
    marginBottom: '5%'
  },
  media: {
    height: isMobileOnly ? windowHeight / 3 : windowHeight / 2.4,
    objectFit: 'cover',
    width: isMobileOnly ? windowWidth : windowWidth / 2.5
  },
  learnText: {
    background: 'linear-gradient(to right, #1b2551, #acafaf)',
    color: 'white',
    '&:hover': {
      backgroundColor: appColor,
      color: 'white'
    }
  },
  cardContent: {
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
    //textAlign: ({ language }) => (language === 'en' ? 'left' : 'right')
  },
  img: {
    objectFit: 'contain',
    width: isMobileOnly ? '100%' : windowWidth / 5.5,
    borderRadius: 10,
    float: 'left',
    marginRight: '1%',
    marginBottom: '1%'
  },
  content: {
    display: 'inline',
    marginBlock: '3%',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word'
  },
})

export default PackagesScreen;