import axios from 'axios';
import createDataContext from './createDataContext';
import { uploadNewImages, deleteImages } from './commons';

const packagesReducer = (state, action) => {
  switch (action.type) {
    case 'upload_packages':
      return { ...state, errorMessage: '' };
    case 'fetch_packages':
      return { ...state, errorMessage: '', packages: action.payload };
    // case 'edit_news':
    //   return {
    //     ...state,
    //     errorMessage: '',
    //     news: state.news.map(newsItem => {
    //       if (newsItem._id === action.payload._id) {
    //         return { ...newsItem, ...action.payload };
    //       } else {
    //         return newsItem;
    //       }
    //     })
    //   };
    case 'delete_packages':
      return { ...state, errorMessage: '' };
    case 'reset_packages':
      return { ...state, errorMessage: '', packages: [] };
    case 'error':
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};

const editImages = async (
  images,
  selectedPackagesId,
  imageKey,
  singleImage
) => {
  const uploadImageRes = await uploadNewImages(
    images,
    `packages`
  );
  if (uploadImageRes.error) {
    return { error: uploadImageRes.error };
  }

  let toBeEditedPackages;
  try {
    const fetchingRes = await axios.get('/api/packages', { params: { selectedPackagesId } });
    toBeEditedPackages = fetchingRes.data[0];
  } catch (err) {
    console.log(err);
    return { error: 'Error in Fetching the Packages to be edited' };
  }

  try {
    let imagesPaths;
    if (singleImage) {
      imagesPaths = uploadImageRes.imgsConfigs.map(
        config => config.data.key
      )[0];
    } else {
      imagesPaths = uploadImageRes.imgsConfigs.map(config => config.data.key);
    }

    await axios.put('/api/packages', {
      selectedPackagesId,
      [imageKey]: imagesPaths
    });

    const deleteRes = await deleteImages([
      toBeEditedPackages.imageUrl
    ]);

    if (deleteRes.error) {
      return { error: deleteRes.error };
    } else {
      return { error: '' };
    }
  } catch (err) {
    console.log(err);
    return { error: 'Error in Editing the item' };
  }
};

const uploadPackages =
  dispatch => async (arabicTitle, englishTitle, image, arabicDescription, englishDescription) => {
    /*Image Config*/
    let imgConfig;
    try {
      imgConfig = await axios.get('/api/image/config', {
        params: {
          path: `packages`
        }
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: 'error',
        payload: 'Something went wrong while uploading packages image config'
      });
      return { error: 'Error in uploading packages image config' };
    }

    /*Upload Image to AWS*/
    try {
      await axios.put(imgConfig.data.url, image, {
        headers: {
          'Content-Type': image.type
        }
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: 'error',
        payload: 'Something went wrong while uploading packages image to AWS'
      });
      return { error: 'Error in Uploading packages image to AWS' };
    }

    try {
      await axios.post('/api/packages', {
        arabicTitle,
        englishTitle,
        imageUrl: imgConfig.data.key,
        arabicDescription,
        englishDescription
      });
      dispatch({ type: 'upload_packages' });
      return { error: '' };
    } catch {
      dispatch({
        type: 'error',
        payload: 'Something went wrong while uploading packages to the database'
      });
      return { error: 'Error in Uploading Packages to the database' };
    }
  };

const fetchPackages = dispatch => async () => {
  try {
    const res = await axios.get('/api/packages');

    dispatch({ type: 'fetch_packages', payload: res.data });
    return { error: '' };
  } catch (err) {
    dispatch({
      type: 'error',
      payload: 'Something went wrong while fetching packages'
    });
    return { error: 'Error in Fetching packages' };
  }
};

const editPackages = dispatch => async (
  selectedPackagesId,
  newArabicTitle,
  newEnglishTitle,
  newArabicDescription,
  newEnglishDescription,
  newImage
) => {
  if (newImage) {
    const res = editImages(
      [newImage],
      selectedPackagesId,
      'imageUrl',
      true
    );
    if (res.error) {
      dispatch({
        type: 'error',
        payload: res.error
      });
      return { error: res.error };
    }
  }

  try {
    await axios.put('/api/packages', {
      selectedPackagesId,
      arabicTitle: newArabicTitle,
      englishTitle: newEnglishTitle,
      arabicDescription: newArabicDescription,
      englishDescription: newEnglishDescription
    });
    dispatch({
      type: 'edit_product',
      payload: {
        selectedPackagesId,
        arabicTitle: newArabicTitle,
        englishTitle: newEnglishTitle,
        arabicDescription: newArabicDescription,
        englishDescription: newEnglishDescription
      }
    });

    return { error: '' };
  } catch (err) {
    console.log(err)
    dispatch({
      type: 'error',
      payload: 'Something went wrong while editing the packages'
    });
    return { error: 'Error in Editing the packages' };
  }
}

const deletePackages = dispatch => async selectedPackagesId => {
  try {
    const fetchingRes = await axios.get('/api/packages', { params: { selectedPackagesId } });
    const imageUrl = fetchingRes.data[0].imageUrl;

    await axios.delete('/api/packages', {
      data: { id: selectedPackagesId }
    });

    deleteImages([imageUrl]);

    dispatch({ type: 'delete_packages' });
    return { error: '' };
  } catch (err) {
    dispatch({
      type: 'error',
      payload: 'Something went wrong while deleting the packages'
    });
    return { error: 'Error in deleting the packages' };
  }
};

const resetPackagesState = dispatch => () => {
  dispatch({ type: 'reset_packages' });
};

export const { Context, Provider } = createDataContext(
  packagesReducer,
  {
    fetchPackages,
    uploadPackages,
    editPackages,
    deletePackages,
    resetPackagesState
  },
  { packages: [], errorMessage: '' }
);
