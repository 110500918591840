import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Button,
  Container,
  TextField,
  Fade,
  Select,
  MenuItem,
  CircularProgress
} from '@material-ui/core';
import { isMobileOnly } from 'react-device-detect';
import CustomModal from '../components/CustomModal';
import { Context as AuthContext } from '../../context/AuthContext';
import { Context as PackagesContext } from '../../context/PackagesContext';
import { windowWidth } from '../../common';
import JoditEditor from 'jodit-react';

const EditPackages = () => {
  const [selectedNewsId, setSelectedNewsId] = useState(null);
  const [selectedNewsEnglishTitle, setSelectedNewsEnglishTitle] = useState(null);
  const [newArabicTitle, setNewArabicTitle] = useState('');
  const [newEnglishTitle, setNewEnglishTitle] = useState('');
  const [newImage, setNewImage] = useState(null);
  const [newArabicDescription, setNewArabicDescription] = useState('');
  const [newEnglishDescription, setNewEnglishDescription] = useState('');
  const [loading, setLoading] = useState(false);

  const [modalText, setModalText] = useState('');
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [resetPhoto, setResetPhoto] = useState(false);

  const navigate = useNavigate();
  const editor = useRef(null);

  const {
    state: { token },
    tryLocalSignin
  } = useContext(AuthContext);
  const {
    fetchPackages,
    editPackages,
    state: { packages }
  } = useContext(PackagesContext);

  useEffect(() => {
    async function fetchPackagesData() {
      setLoading(true);
      await fetchPackages();
      setLoading(false);
    }

    tryLocalSignin(navigate, '/edit_packages');
    fetchPackagesData();
  }, []);

  const handleSubmit = async () => {
    setLoading(true);

    const res = await editPackages(
      selectedNewsId,
      newArabicTitle,
      newEnglishTitle,
      newArabicDescription,
      newEnglishDescription,
      newImage
    );

    if (res.error) {
      setModalText('There is a problem in editing the Package!');
    } else {
      setModalText('Package edited successfully');
      setSelectedNewsId(null);
      setSelectedNewsEnglishTitle(null);
      setNewArabicTitle('');
      setNewEnglishTitle('');
      setNewArabicDescription('');
      setNewEnglishDescription('');
      setNewImage(null);
      await fetchPackages();
    }

    setShowConfirmButton(false);
    setModalVisible(true);
    setLoading(false);
  };

  const validateImagesSizes = async event => {
    event.preventDefault();

    Object.values(event.target.files).forEach(imgFile => {
      if (imgFile.size > 1048576) {
        setModalText('The image exceeds 1MB limit size');
        setModalVisible(true);
        setShowConfirmButton(false);
        setResetPhoto(true);
        return;
      }
    });

    setNewImage(event.target.files[0]);
  };

  const classes = useStyles();

  if (!token) {
    return null;
  }

  return (
    <div>
      <Container style={{ marginTop: '1%' }}>
        {loading && (
          <CircularProgress
            style={{
              display: 'flex',
              position: 'absolute',
              alignSelf: 'center',
              top: '50%',
              left: '50%'
            }}
          />
        )}
        <Fade timeout={1000} in={true}>
          <div>
            <Typography className={classes.title}>Edit Packages</Typography>
            <Divider />
            <Grid
              className={
                isMobileOnly ? classes.mobileContainer : classes.container
              }
              container
            >
              <Grid md={4} xs={12}>
                <Select
                  placeholder="Choose Package"
                  variant="outlined"
                  className={classes.selectNews}
                  onChange={event => {
                    setSelectedNewsId(event.target.value.id);
                    setSelectedNewsEnglishTitle(event.target.value.englishTitle);
                    setNewArabicTitle(event.target.value.arabicTitle);
                    setNewEnglishTitle(event.target.value.englishTitle);
                    setNewArabicDescription(event.target.value.arabicDescription);
                    setNewEnglishDescription(event.target.value.englishDescription);
                  }}
                >
                  {packages.map(packagesObj => (
                    <MenuItem value={packagesObj}>{packagesObj.englishTitle}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid md={4} xs={12}>
                <TextField
                  value={newEnglishTitle}
                  onChange={e => setNewEnglishTitle(e.target.value)}
                  placeholder='New English "Package" Name'
                  variant="outlined"
                  className={classes.textField}
                />
              </Grid>
              <Grid md={4} xs={12}>
                <TextField
                  value={newArabicTitle}
                  onChange={e => setNewArabicTitle(e.target.value)}
                  placeholder='New Arabic "Package" Name'
                  variant="outlined"
                  className={classes.textField}
                />
              </Grid>
              <Grid item md={12}>
                <Typography className={classes.title}>New English Description</Typography>
                {/* <TextField
                                    value={newEnglishDescription}
                                    onChange={e => setNewEnglishDescription(e.target.value)}
                                    className={classes.boxField}
                                    id="outlined-basic"
                                    placeholder="Type the new english description here"
                                    variant="outlined"
                                    color="primary"
                                    //fullWidth
                                    multiline
                                    rows={6}
                                /> */}
                <JoditEditor
                  value={newEnglishDescription}
                  onChange={e => setNewEnglishDescription(e)}
                  ref={editor}
                />
              </Grid>
              <Grid item md={12}>
                <Typography className={classes.title}>New Arabic Description</Typography>
                {/* <TextField
                                    value={newArabicDescription}
                                    onChange={e => setNewArabicDescription(e.target.value)}
                                    className={classes.boxField}
                                    id="outlined-basic"
                                    placeholder="Type the new arabic description here"
                                    variant="outlined"
                                    color="primary"
                                    //fullWidth
                                    multiline
                                    rows={6}
                                /> */}
                <JoditEditor
                  value={newArabicDescription}
                  onChange={e => setNewArabicDescription(e)}
                  ref={editor}
                />
              </Grid>
              <Grid direction="row" md={4} xs={12}>
                <Button
                  className={classes.uploadButton}
                  variant="contained"
                  component="label"
                  color="primary"
                >
                  Upload New Photo
                  <input
                    name="photos"
                    type="file"
                    id="files"
                    onChange={event => validateImagesSizes(event)}
                    hidden
                  />
                </Button>
                <Typography variant="h6">
                  {newImage
                    ? 'New Photo Selected'
                    : 'Please Select New Photo'}
                </Typography>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="secondary"
              className={classes.submitButton}
              onClick={() => {
                if (!selectedNewsEnglishTitle ||
                  !newArabicTitle ||
                  !newEnglishTitle ||
                  !newArabicDescription ||
                  !newEnglishDescription) {
                  return;
                }

                setModalText('Do you want to submit this?');
                setShowConfirmButton(true);
                setModalVisible(true);
                setResetPhoto(false);
              }}
            >
              Submit
            </Button>
            <CustomModal
              title="Warning!"
              content={modalText}
              visible={modalVisible}
              onCancel={() => {
                setModalVisible(false);
                if (resetPhoto) setNewImage(null);
              }}
              onConfirm={handleSubmit}
              showConfirmButton={showConfirmButton}
              loading={loading}
            />
          </div>
        </Fade>
      </Container>
    </div>
  );
};

const useStyles = makeStyles({
  title: {
    fontSize: '1.6em',
    marginBlock: '1%'
  },
  container: {
    marginTop: '2%'
  },
  mobileContainer: {
    marginTop: '5%'
  },
  textField: {
    width: isMobileOnly ? '85%' : '60%',
    marginBottom: isMobileOnly ? '5%' : 0
  },
  boxField: {
    marginTop: '2%',
    marginBottom: '2%',
    width: windowWidth / 1.5
  },
  uploadButton: {
    marginTop: '5%'
  },
  submitButton: {
    float: 'right',
    width: isMobileOnly ? '35%' : '10%',
    marginTop: isMobileOnly ? '5%' : 0,
    marginBottom: '2%'
  },
  selectNews: {
    width: isMobileOnly ? '85%' : '50%',
    marginBottom: isMobileOnly ? '5%' : 0
  }
});

export default EditPackages;