import axios from 'axios';
import createDataContext from './createDataContext';

const orderReducer = (state, action) => {
  switch (action.type) {
    case 'book_appointment':
      return { ...state, errorMessage: '' };
    case 'fetch_appointment':
      return { ...state, appointments: action.payload };
    case 'update_notes':
      return { ...state, errorMessage: '' };
    case 'update_status':
      return { ...state, errorMessage: '' };
    case 'error':
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};

const fetchAppointments = dispatch => async () => {
  try {
    const res = await axios.get('/api/appointments');
    dispatch({ type: 'fetch_appointment', payload: res.data.reverse() });
    return { error: '', appointments: res.data.reverse() };
  } catch (err) {
    dispatch({
      type: 'error',
      payload: 'Something went wrong while fetching the appointments'
    });
    return { error: 'fetching Appointments failed!', appointments: [] };
  }
};

const bookAppointment = dispatch => async appointmentDetails => {
  try {
    await axios.post('/api/appointment', appointmentDetails);
    dispatch({ type: 'book_appointment' });
    return { error: '' };
  } catch (err) {
    dispatch({
      type: 'error',
      payload: 'Something went wrong while booking the appointment'
    });
    return { error: 'appointment booking failed!' };
  }
};

const updateNotes = dispatch => async (id, notes) => {
  try {
    await axios.put('/api/appointment', {
      id,
      notes
    });
    dispatch({ type: 'update_notes' });
    return { error: '' };
  } catch (err) {
    dispatch({
      type: 'error',
      payload: 'Something went wrong while updating notes appointment'
    });
    return { error: 'appointment updating notes failed!' };
  }
}

const updateStatus = dispatch => async (id, isDone) => {
  try {
    await axios.put('/api/appointment/status', {
      id,
      isDone
    });
    dispatch({ type: 'update_status' });
    return { error: '' };
  } catch (err) {
    dispatch({
      type: 'error',
      payload: 'Something went wrong while updating appointment status'
    });
    return { error: 'appointment status updating failed!' };
  }
}

export const { Context, Provider } = createDataContext(
  orderReducer,
  {
    bookAppointment,
    fetchAppointments,
    updateNotes,
    updateStatus
  },
  { appointments: [], errorMessage: '' }
);
