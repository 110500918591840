import React, { useContext } from 'react';
import { Box, Container, Typography, makeStyles, Divider } from '@material-ui/core';
import NavBar from '../components/Navbar';
import { Context as LanguageContext } from '../context/LanguageContext';
import { appColor, windowHeight } from '../common';
import { PhoneInTalk, MailOutline } from '@material-ui/icons';
import { isMobileOnly } from 'react-device-detect';
import Footer from '../components/Footer';

const ContactScreen = () => {
  const {
    state: { language },
  } = useContext(LanguageContext);

  const props = {
    language,
  };

  const classes = useStyles(props);
  return (
    <Box>
      <NavBar />
      <div >
        <Container style={{ direction: language === 'en' ? 'ltr' : 'rtl', marginTop: '5%', minHeight: windowHeight / 1.8 }}>
          <Typography className={classes.title}>
            {language === 'en' ? 'Contact Us' : 'تواصل معنا'}
          </Typography>
          <Divider />
          <Box className={classes.contentContainer}>
            <Typography className={classes.content}>
              {language === 'en' ? 'Feel free to contact us on:' : 'تواصل معنا عبر وسائل التواصل التالية:'}
            </Typography>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <PhoneInTalk className={classes.icon} />
              <Typography className={classes.details}>
                {language === 'en' ? `Phone Number: 064654445` : `رقم الهاتف: 064654445`}
              </Typography>
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center', marginTop: isMobileOnly ? '3%' : '' }}>
              <MailOutline className={classes.icon} />
              <Typography className={classes.details}>
                {language === 'en' ? `E-mail: info@smartlabsgroup.com` : `الإيميل: info@smartlabsgroup.com`}
              </Typography>
            </Box>
          </Box>
          <img
            src={require('../images/logo.png')}
            alt="logo"
            className={classes.logo}
          />
        </Container>
        <Footer />
      </div>
    </Box>
  );
};

export const useStyles = makeStyles({
  title: {
    fontSize: '1.7em',
    color: appColor,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1%',
  },
  content: {
    fontSize: '2.5vh'
  },
  details: {
    fontSize: '2.5vh',
    marginTop: '1%'
  },
  icon: {
    color: appColor,
    marginRight: '1%',
    marginTop: '1%'
  },
  logo: {
    objectFit: 'contain',
    height: isMobileOnly ? windowHeight / 20 : windowHeight / 16,
    marginTop: '5%',
    marginBottom: isMobileOnly ? '10%' : 0
  }
});

export default ContactScreen;