import axios from 'axios';
import createDataContext from './createDataContext';
import { uploadNewImages, deleteImages } from './commons';

const bannerReducer = (state, action) => {
  switch (action.type) {
    case 'error':
      return { ...state, errorMessage: action.payload };
    case 'upload_images':
      return { ...state, errorMessage: '' };
    case 'fetch_images':
        return { ...state, errorMessage: '', images: action.payload }
    case 'delete_image':
      return { ...state, errorMessage: '' };
    default:
      return state;
  }
};

const uploadBannerImages = dispatch => async bannerImages => {
  const uploadImageRes = await uploadNewImages(bannerImages, 'Banner');
  if (uploadImageRes.error) {
    return { error: uploadImageRes.error };
  }

  /*Upload Banner Images to Database*/
  try {
    await axios.post('/api/banner', {
      bannerImagesUrls: uploadImageRes.imgsConfigs.map(config => config.data.key)
    });
    dispatch({ type: 'upload_images' });

    return { error: '' };
  } catch {
    dispatch({
      type: 'error',
      payload:
        'Something went wrong while uploading banner images to the database'
    });
    return { error: 'Error in Uploading Banner images to the database' };
  }
};

const fetchBannerImages = dispatch => async () => {
    try {
        const res = await axios.get('/api/banner');
        dispatch({ type: 'fetch_images', payload: res.data });
        return { error: '' };
    } catch(err) {
        dispatch({
            type: 'error',
            payload:
              'Something went wrong while fetching banner images'
        });
        return { error: 'Error in Fetching Banner images' };
    }
};

const deleteBannerImage = dispatch => async (id, imageUrl) => {
  try {
      await axios.delete('/api/banner', {
        data: { id }
      });
      dispatch({ type: 'delete_image' });

      const deleteRes = await deleteImages([imageUrl]);

      if (deleteRes.error) {
        console.log(deleteRes.error);
      }

      return { error: '' };
  } catch(err) {
      dispatch({
          type: 'error',
          payload:
            'Something went wrong while deleting banner image'
      });
      return { error: 'Error in Deleting Banner image' };
  }
};

export const { Context, Provider } = createDataContext(
  bannerReducer,
  { fetchBannerImages, uploadBannerImages, deleteBannerImage },
  { images: [], errorMessage: '' }
);
