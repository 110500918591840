import React, { useContext, useEffect, useRef } from "react";
import { useNavigate, Outlet } from 'react-router-dom';
import {
  Box,
  Typography,
  makeStyles,
  Button,
} from '@material-ui/core';
import { Context as LanguageContext } from '../context/LanguageContext';
import { Context as CategoryContext } from '../context/CategoryContext';
import { isMobile } from "react-device-detect";

const AWS_URL = 'https://smartlabs-bucket.s3.eu-central-1.amazonaws.com/';

const TestsSidebar = ({ children, title, setArabicName, setEnglishName, setArabicDescription, setEnglishDescription, setImageUrl }) => {
  const firstButtonRef = useRef(null);
  const {
    state: { language },
  } = useContext(LanguageContext);

  const { fetchCategories, fetchAllProducts, searchProducts, state: { categories, allProducts } } = useContext(CategoryContext)

  const classes = useStyles();

  useEffect(() => {
    async function fetchData() {
      await fetchCategories();
    }

    if (firstButtonRef.current) {
      firstButtonRef.current.click();
    }

    fetchData()
  }, [categories]);

  return (
    <Box className={classes.blogsTitlesContainer}>
      <Typography className={classes.blogsTitlesText}>{title}</Typography>
      <hr />
      {categories.map((item, index) => {
        const products = allProducts.filter(product => product.categoryId === item.id);
        return (
          <>
            <Button
              variant="text"
              ref={index === 0 ? firstButtonRef : null}
              onClick={() => {
                setArabicName(item.arabicName);
                setEnglishName(item.englishName);
                setArabicDescription(item.arabicDescription);
                setEnglishDescription(item.englishDescription);
                setImageUrl(AWS_URL + item.imageUrl);
              }}
            >
              <Typography>
                {language === 'en' ? item.englishName : item.arabicName}
              </Typography>
            </Button>
            <hr />
          </>
        )
      })}
      <div style={{ width: '100%' }}>{children}<Outlet /></div>
    </Box>
  );
};

export const useStyles = makeStyles(theme => ({
  blogsTitlesContainer: {
    boxShadow: theme.shadows[3],
    width: isMobile ? '100%' : '85%',
    padding: '3%',
    borderRadius: 10,
    marginBottom: isMobile ? '5%' : '1%'
  },
  blogsTitlesText: {
    fontSize: '1.4em',
  },
}));

export default TestsSidebar;