import React, { useState, useContext, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Box,
  Button,
  makeStyles,
  TextField
} from '@material-ui/core';
import NavBar from "../components/Navbar";
import { Context as LanguageContext } from '../context/LanguageContext';
import { Context as OrderContext } from '../context/OrderContext';
import { appColor, windowWidth } from "../common";
import { isMobileOnly } from "react-device-detect";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Aos from 'aos';
import 'aos/dist/aos.css';
import 'date-fns';
import CustomModal from '../admin/components/CustomModal';
import Footer from "../components/Footer";

const BookingScreen = () => {
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [modalText, setModalText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Aos.init({
      duration: 2000
    });
  })

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const {
    state: { language },
  } = useContext(LanguageContext);
  const { bookAppointment } = useContext(OrderContext);

  const props = {
    language
  };

  const classes = useStyles(props);

  const handleSubmit = async () => {
    setLoading(true);

    const dateAndTime = selectedDate.toISOString().split('T');
    const date = dateAndTime[0];
    const time = dateAndTime[1].split('.')[0];

    const res = await bookAppointment({
      name: fullName,
      phoneNumber,
      address,
      date, // date: selectedDate
      time
    });

    if (res.error) {
      setModalText('There is a problem in booking the appointment!');
    } else {
      setModalText('Appointment booked successfully');
      setFullName('');
      setPhoneNumber('');
      setAddress('');
    }

    setShowConfirmButton(false);
    setModalVisible(true);
    setLoading(false);
  }

  return (
    <Box>
      <NavBar />
      <Container data-aos={language === 'en' ? "fade-right" : 'fade-left'} style={{ direction: language === 'en' ? 'ltr' : 'rtl', marginTop: '5%' }}>
        <Typography className={classes.title}>
          {language === 'en' ? 'Book An Appointment' : 'حجز موعد'}
        </Typography>
        <Divider />
        <Grid container>
          <Grid item md={6} xs={12}>
            <Typography className={classes.label}>
              {language === 'en' ? "Full Name" : 'الإسم الكامل'}
            </Typography>
            <TextField
              value={fullName}
              onChange={e => setFullName(e.target.value)}
              placeholder={language === 'en' ? "Full Name" : 'أدخل الإسم الكامل...'}
              variant="outlined"
              className={classes.textField}
              InputProps={{
                style: {
                  direction: language === 'en' ? 'ltr' : 'rtl'
                }
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography className={classes.label}>
              {language === 'en' ? "Phone Number" : 'رقم الهاتف'}
            </Typography>
            <TextField
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
              placeholder={language === 'en' ? "Phone Number" : 'أدخل رقم الهاتف...'}
              type="number"
              variant="outlined"
              className={classes.textField}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography className={classes.label}>
              {language === 'en' ? "Address" : 'العنوان'}
            </Typography>
            <TextField
              value={address}
              onChange={e => setAddress(e.target.value)}
              placeholder={language === 'en' ? "Address" : 'أدخل العنوان...'}
              variant="outlined"
              className={classes.textField}
              InputProps={{
                className: classes.input
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography className={classes.label}>
              {language === 'en' ? "Date" : 'التاريخ'}
            </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                inputVariant="outlined"
                className={classes.textField}
                style={{ margin: 0 }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography className={classes.label}>
              {language === 'en' ? "Time" : 'الوقت'}
            </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardTimePicker
                margin="normal"
                id="time-picker"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
                inputVariant="outlined"
                className={classes.textField}
                style={{ margin: 0 }}
                minutesStep={5}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          className={classes.submitButton}
          style={{ float: language === 'en' ? 'right' : 'left' }}
          onClick={() => {
            if (!fullName || !phoneNumber || !address || !selectedDate) {
              return;
            }

            setModalText(language === 'en' ? 'Do you want to submit this?' : 'هل أنت متأكد من حجز هذا الموعد؟');
            setShowConfirmButton(true);
            setModalVisible(true);
          }}
        >
          {language === 'en' ? 'Submit' : 'أرسل'}
        </Button>
        <CustomModal
          title={language === 'en' ? "Warning!" : 'رسالة تأكيد'}
          content={modalText}
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          onConfirm={handleSubmit}
          showConfirmButton={showConfirmButton}
          loading={loading}
        />
      </Container>
      {/* <Footer/> */}
    </Box>
  );
};

export const useStyles = makeStyles({
  title: {
    fontSize: '1.8em',
  },
  label: {
    fontSize: '1.5em',
    marginTop: '3%',
    marginBottom: '2%',
  },
  textField: {
    marginBottom: '3%',
    width: isMobileOnly ? '100%' : '90%',
    //float: ({ language }) => (language === 'en' ? 'left' : 'right'),
  },
  input: {
    textAlign: ({ language }) => (language === 'en' ? 'left' : 'right')
  },
  submitButton: {
    //float: 'right',
    width: isMobileOnly ? '35%' : '10%',
    marginTop: '5%',
    marginBottom: '1%',
    background: 'linear-gradient(to right, #1b2551, #acafaf)',
    color: 'white',
    '&:hover': {
      backgroundColor: appColor,
      color: 'white'
    }
  }
})

export default BookingScreen;