import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Button,
  Container,
  TextField,
  Fade,
  Select,
  MenuItem,
  CircularProgress
} from '@material-ui/core';
import { isMobileOnly } from 'react-device-detect';
import CustomModal from '../components/CustomModal';
import { Context as AuthContext } from '../../context/AuthContext';
import { Context as CategoryContext } from '../../context/CategoryContext';
import { windowWidth } from '../../common';
import JoditEditor from 'jodit-react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const AddProducts = () => {
  const [englishCategoryName, setEnglishCategoryName] = useState('');
  const [arabicCategoryName, setArabicCategoryName] = useState('');
  const [englishProductName, setEnglishProductName] = useState('');
  const [arabicProductName, setArabicProductName] = useState('');
  const [englishDescription, setEnglishDescription] = useState('');
  const [arabicDescription, setArabicDescription] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [image, setImage] = useState(null);

  const [modalText, setModalText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [submitType, setSubmitType] = useState(null);
  const [resetPhoto, setResetPhoto] = useState(false);
  const [loading, setLoading] = useState(false);

  const editor = useRef(null);
  const config = {
    buttons: ["bold", "italic", "underline", "link", "unlink"]
  }

  const navigate = useNavigate();

  const {
    state: { token },
    tryLocalSignin
  } = useContext(AuthContext);
  const {
    fetchCategories,
    uploadCategory,
    uploadProduct,
    state: { categories }
  } = useContext(CategoryContext);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await fetchCategories();
      setLoading(false);
    }

    tryLocalSignin(navigate, '/add_products');
    fetchData();
  }, []);

  const classes = useStyles();

  const handleSubmit = async () => {
    setLoading(true);

    if (submitType === 'category') {
      const res = await uploadCategory(
        arabicCategoryName,
        englishCategoryName
      );

      if (res.error) {
        setModalText('There is a problem in uploading the Category!');
      } else {
        setModalText('Category uploaded successfully');
        setArabicCategoryName('');
        setEnglishCategoryName('');
        fetchCategories();
      }
    } else if (submitType === 'product') {
      const res = await uploadProduct(
        arabicProductName,
        englishProductName,
        arabicDescription,
        englishDescription,
        image,
        selectedCategoryId
      );

      if (res.error) {
        setModalText('There is a problem in uploading the product!');
      } else {
        setModalText('Product uploaded successfully');
        setArabicProductName('');
        setEnglishProductName('');
        setArabicDescription('');
        setEnglishDescription('');
        setImage(null);
        setSelectedCategoryId(null);
        fetchCategories();
      }
    }

    setShowConfirmButton(false);
    setModalVisible(true);
    setSubmitType(null);
    setLoading(false);
  };

  const validateImagesSizes = async event => {
    event.preventDefault();

    Object.values(event.target.files).forEach(imgFile => {
      if (imgFile.size > 1048576) {
        setModalText('There are images that exceed 1MB limit size');
        setModalVisible(true);
        setShowConfirmButton(false);
        setResetPhoto(true);
        return;
      }
    });

    setImage(event.target.files[0]);
  };

  if (!token) {
    return null;
  }

  // console.log("englishDescription", englishDescription);
  const index = englishDescription.search('<a');
  const insertedStyle = ' target="_blank" rel="noopener noreferrer" '
  const modified = englishDescription.slice(0, index + 2) + insertedStyle + englishDescription.slice(index + 2, englishDescription.length)

  return (
    <div>
      <Container style={{ marginTop: '1%' }}>
        {loading && (
          <CircularProgress
            style={{
              display: 'flex',
              position: 'absolute',
              alignSelf: 'center',
              top: '50%',
              left: '50%'
            }}
          />
        )}
        <Fade timeout={1000} in={true}>
          <div>
            <Typography className={classes.title}>Add Category</Typography>
            <Divider />
            <Grid
              className={
                isMobileOnly ? classes.mobileContainer : classes.container
              }
              container
            >
              <Grid md={4} xs={12}>
                <TextField
                  value={englishCategoryName}
                  onChange={e => setEnglishCategoryName(e.target.value)}
                  placeholder="Main English Category Name"
                  variant="outlined"
                  className={classes.textField}
                />
              </Grid>
              <Grid md={4} xs={12}>
                <TextField
                  value={arabicCategoryName}
                  onChange={e => setArabicCategoryName(e.target.value)}
                  placeholder="Main Arabic Category Name"
                  variant="outlined"
                  className={classes.textField}
                />
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="secondary"
              className={classes.submitButton}
              onClick={() => {
                if (
                  !englishCategoryName ||
                  !arabicCategoryName
                ) {
                  return;
                }

                setModalText('Do you want to submit this?');
                setSubmitType('category');
                setShowConfirmButton(true);
                setModalVisible(true);
              }}
            >
              Submit
            </Button>
            <Typography style={{ marginTop: '5%' }} className={classes.title}>
              Add Test
            </Typography>
            <Divider />
            <Grid container>
              <Grid item md={4} xs={12}>
                <Typography style={{ marginTop: '5%' }} className={classes.title}>
                  Choose Category
                </Typography>
                <Select
                  placeholder="Choose New"
                  variant="outlined"
                  className={classes.selectCategory}
                  onChange={event => {
                    setSelectedCategoryId(event.target.value.id);
                  }}
                >
                  {categories.map(category => (
                    <MenuItem value={category}>{category.englishName}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  value={englishProductName}
                  onChange={e => setEnglishProductName(e.target.value)}
                  placeholder="English Test Name"
                  variant="outlined"
                  className={classes.subTextField}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  value={arabicProductName}
                  onChange={e => setArabicProductName(e.target.value)}
                  placeholder="Arabic Test Name"
                  variant="outlined"
                  className={classes.subTextField}
                />
              </Grid>
              <Grid item md={12}>
                <Typography className={classes.title}>English Description</Typography>
                <JoditEditor
                  value={englishDescription}
                  onChange={e => setEnglishDescription(e)}
                  ref={editor}
                />
              </Grid>
              <Grid item md={12} style={{ marginBottom: '3%' }}>
                <Typography className={classes.title}>Arabic Description</Typography>
                <JoditEditor
                  value={arabicDescription}
                  onChange={e => setArabicDescription(e)}
                  ref={editor}
                />
              </Grid>
              <Grid direction="row" md={4} xs={12}>
                <Button
                  className={isMobileOnly ? classes.uploadButton : ''}
                  variant="contained"
                  component="label"
                  color="primary"
                >
                  Upload Photo
                  <input
                    name="photos"
                    type="file"
                    id="files"
                    onChange={event => validateImagesSizes(event)}
                    hidden
                  />
                </Button>
                <Typography variant="h6">
                  {image ? 'Image Selected' : 'Please Select Photo'}
                </Typography>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="secondary"
              className={classes.submitButton}
              onClick={() => {
                if (
                  !selectedCategoryId ||
                  !englishProductName ||
                  !arabicProductName ||
                  !englishDescription ||
                  !arabicDescription ||
                  !image
                ) {
                  return;
                }
                setModalText('Do you want to submit this?');
                setSubmitType('product');
                setShowConfirmButton(true);
                setModalVisible(true);
                setResetPhoto(false);
              }}
            >
              Submit
            </Button>
            <CustomModal
              title="Warning!"
              content={modalText}
              visible={modalVisible}
              onCancel={() => {
                setSubmitType(null);
                setModalVisible(false);
                if (resetPhoto) setImage(null);
              }}
              onConfirm={handleSubmit}
              showConfirmButton={showConfirmButton}
              loading={loading}
            />
          </div>
        </Fade>
      </Container>
    </div>
  );
};

const useStyles = makeStyles({
  title: {
    fontSize: '1.6em',
    marginTop: '1%'
  },
  container: {
    marginTop: '2%'
  },
  mobileContainer: {
    marginTop: '5%'
  },
  selectCategory: {
    width: isMobileOnly ? '85%' : '70%',
    marginBottom: isMobileOnly ? '5%' : 0,
    marginTop: '3%'
  },
  textField: {
    width: isMobileOnly ? '85%' : '70%',
    marginBottom: isMobileOnly ? '5%' : 0,
  },
  subTextField: {
    width: isMobileOnly ? '85%' : '70%',
    marginBottom: isMobileOnly ? '5%' : 0,
    marginTop: isMobileOnly ? 0 : '20%'
  },
  boxField: {
    marginTop: '2%',
    marginBottom: '2%',
    width: windowWidth / 1.5
  },
  uploadButton: {
    marginTop: '5%'
  },
  submitButton: {
    float: 'right',
    width: isMobileOnly ? '35%' : '10%',
    marginTop: isMobileOnly ? '5%' : 0,
    marginBottom: '2%'
  }
});

export default AddProducts;
