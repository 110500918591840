import React, { useState, useContext } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import {
  Drawer,
  makeStyles,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  useTheme,
  useMediaQuery,
  Collapse,
} from '@material-ui/core';
import { Context as AuthContext } from '../../context/AuthContext';

import {
  Add,
  Edit,
  Delete,
  ExitToApp,
  Menu,
  ExpandMore,
  ExpandLess,
  Inbox,
  PhotoLibrary,
  NotificationsActive,
  Message,
  Group,
  PlayCircleFilled
} from '@material-ui/icons';
import { appColor } from '../../common';

const drawerWidth = 240;

const windowHeight = window.innerHeight;
//const windowWidth = window.innerWidth;

const Sidebar = ({ children }) => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);

  const { signout } = useContext(AuthContext);

  const menuItems = [
    {
      title: 'Add',
      icon: <Add className={classes.addIcon} />,
      subs: [
        { subname: 'Add News', subPath: '/add_news' },
        { subname: 'Add Tests', subPath: '/add_products' },
        { subname: 'Add Packages', subPath: '/add_packages' },
        { subname: 'Add Blogs', subPath: '/add_blogs' },
      ]
    },
    {
      title: 'Edit',
      icon: <Edit className={classes.icon} />,
      subs: [
        { subname: 'Edit News', subPath: '/edit_news' },
        { subname: 'Edit Tests', subPath: '/edit_products' },
        { subname: 'Edit Packages', subPath: '/edit_packages' },
        { subname: 'Edit Blogs', subPath: '/edit_blogs' },
      ]
    },
    {
      title: 'Delete',
      icon: <Delete className={classes.icon} />,
      subs: [
        { subname: 'Delete News', subPath: '/delete_news' },
        { subname: 'Delete Tests', subPath: '/delete_products' },
        { subname: 'Delete Packages', subPath: '/delete_packages' },
        { subname: 'Delete Blogs', subPath: '/delete_blogs' },
      ]
    },
    {
      title: 'Banner',
      icon: <PhotoLibrary className={classes.icon} />,
      path: '/banner'
    },
    {
      title: 'Orders',
      icon: <Inbox className={classes.icon} />,
      path: '/orders'
    },
    {
      title: 'Pop-Up',
      icon: <Message className={classes.icon} />,
      path: '/pop-up'
    },
    {
      title: 'Notifications',
      icon: <NotificationsActive className={classes.icon} />,
      path: '/notifications'
    },
    {
      title: 'Customers',
      icon: <Group className={classes.icon} />,
      path: '/customers'
    },
    {
      title: 'Video',
      icon: <PlayCircleFilled className={classes.icon} />,
      path: '/admin_video'
    },
    {
      title: 'Logout',
      icon: <ExitToApp className={classes.icon} />
    }
  ];

  return (
    <div className={classes.root}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        className={classes.menuButton}
      >
        <Menu />
      </IconButton>
      <Drawer
        variant={isMdUp ? 'permanent' : 'temporary'}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        className={classes.drawer}
        classes={{ paper: classes.drawerTitle }}
        ModalProps={{ keepMounted: true }}
      >
        <Container style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '10%',
          marginBottom: '10%'
        }}
        >
          <img
            src={require('../../images/logo.png')}
            alt="logo"
            style={{
              objectFit: 'contain',
              height: '100%',
              width: '100%',
            }}
          />
        </Container>
        <List className={classes.list}>
          {menuItems.map(item => {
            let isOpen;
            if (item.title === 'Add') {
              isOpen = openAdd;
            } else if (item.title === 'Edit') {
              isOpen = openEdit;
            } else if (item.title === 'Delete') {
              isOpen = openDelete;
            }
            return (
              <>
                <ListItem
                  button
                  key={item.title}
                  onClick={() => {
                    if (item.title === 'Add') {
                      setOpenAdd(!openAdd);
                    } else if (item.title === 'Edit') {
                      setOpenEdit(!openEdit);
                    } else if (item.title === 'Delete') {
                      setOpenDelete(!openDelete);
                    } else if (item.title === 'Logout') {
                      signout(navigate);
                    } else if (item.path) {
                      handleDrawerToggle();
                      navigate(item.path);
                    }
                  }}
                  className={
                    location.pathname === item.path ? classes.active : null
                  }
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText
                    className={classes.drawerLink}
                    primary={item.title}
                  />
                  {item.subs ? isOpen ? <ExpandLess /> : <ExpandMore /> : null}
                </ListItem>
                {item.subs ? (
                  <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <List
                      component="div"
                      disablePadding
                      className={classes.subList}
                    >
                      {item.subs.map(({ subname, subPath }) => (
                        <ListItem
                          onClick={() => {
                            navigate(subPath);
                            handleDrawerToggle();
                          }}
                          button
                          className={classes.nested}
                        >
                          <ListItemText primary={subname} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                ) : null}
              </>
            );
          })}
        </List>
      </Drawer>
      <div style={{ width: '100%' }}>{children}<Outlet /></div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth
  },
  drawerTitle: {
    width: drawerWidth,
    background: '#f5f5f5'
  },
  root: {
    display: 'flex'
  },
  title: {
    backgroundColor: '#f9f9f9',
    width: '100%'
  },
  drawerLogo: {
    color: 'white',
    //marginTop: '5%'
  },
  list: {
    overflowY: 'auto',
    margin: 0,
    padding: 0,
    listStyle: 'none',
    height: '100%',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)'
    }
  },
  active: {
    backgroundColor: 'rgba(0,0,0,0.1)'
  },
  addIcon: {
    backgroundColor: appColor,
    borderRadius: 50,
    color: 'white'
  },
  icon: {
    color: appColor
  },
  menuButton: {
    marginRight: theme.spacing(0),
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
    height: windowHeight / 20
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  subList: {
    backgroundColor: '#e6e6e6'
  },
}));

export default Sidebar;
