import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Button,
  Container,
  TextField,
  Fade
} from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import CustomModal from './components/CustomModal';
import { Context as AuthContext } from '../context/AuthContext';
import { Context as NotificationsContext } from '../context/NotificationsContext';

const Notifications = () => {
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationText, setNotificationText] = useState('');
  const [modalText, setModalText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const navigate = useNavigate();

  const {
    state: { token },
    tryLocalSignin
  } = useContext(AuthContext);
  const { sendNotification } = useContext(NotificationsContext);

  const handleSubmit = async () => {
    setLoading(true);
    const res = await sendNotification(notificationTitle, notificationText);

    if (res.error) {
      setModalText('There is a problem in sending the notification');
      setShowConfirmButton(false);
    } else {
      setModalText('Notification sent successfully');
      setShowConfirmButton(false);
    }
    setLoading(false);
  }

  useEffect(() => {
    tryLocalSignin(navigate, '/notifications');
  }, []);

  if (!token) {
    return null;
  }

  return (
    <Container>
      <Typography className={classes.title}>Notifications</Typography>
      <Divider />
      <Grid className={classes.container} container>
        <Grid xs={12}>
          <Typography className={classes.label}>Notification Title</Typography>
          <TextField
            value={notificationTitle}
            onChange={e => setNotificationTitle(e.target.value)}
            placeholder="Notification Title"
            variant="outlined"
            className={classes.textField}
          />
        </Grid>
        <Grid xs={12}>
          <Typography className={classes.label}>Notification Text</Typography>
          <TextField
            value={notificationText}
            onChange={e => setNotificationText(e.target.value)}
            placeholder="Notification Text"
            variant="outlined"
            multiline
            className={classes.textField}
          />
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="secondary"
        className={classes.submitButton}
        onClick={() => {
          if (!notificationTitle || !notificationText) {
            return;
          }
          setModalText('Do you want to Send this notification?');
          setShowConfirmButton(true);
          setModalVisible(true);
        }}
      >
        Submit
      </Button>
      <CustomModal
        title="Warning!"
        content={modalText}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
        onConfirm={handleSubmit}
        showConfirmButton={showConfirmButton}
        loading={loading}
      />
    </Container>
  );
};

const useStyles = makeStyles({
  title: {
    fontSize: '1.6em',
    marginBlock: '1%'
  },
  container: {
    marginTop: isMobile ? '5%' : '2%'
  },
  label: {
    fontSize: '1.4em',
    marginBottom: '1%'
  },
  textField: {
    marginBottom: isMobile ? '5%' : '3%',
    width: isMobile ? '100%' : '50%'
  },
  submitButton: {
    float: 'right',
    width: isMobile ? '35%' : '10%',
    marginTop: '1%',
    marginBottom: '2%'
  }
});

export default Notifications;