import React, { useState, useContext, useEffect } from 'react';
import { Box, Container, Typography, makeStyles, Divider, Grid, Button, SvgIcon } from '@material-ui/core';
import { Explore, PhoneInTalk, LocationOn } from '@material-ui/icons';
import { isMobile, isTablet } from 'react-device-detect';
import { findNearest } from 'geolib';
import Aos from 'aos';
import 'aos/dist/aos.css';
import NavBar from '../components/Navbar';
import { Context as LanguageContext } from '../context/LanguageContext';
import CustomModal from '../admin/components/CustomModal';
import { appColor, windowHeight } from '../common';
import * as data from '../Data';
import Footer from '../components/Footer';

const LocationScreen = () => {
  const [modalText, setModalText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const {
    state: { language },
  } = useContext(LanguageContext);

  const props = {
    language,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({
      duration: 2000
    });
  })

  const classes = useStyles(props);

  const getLocationPermission = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then(async function (result) {
            if (result.state === "granted") {
              const position = await getPosition();
              resolve(position.coords);
            } else if (result.state === "prompt") {
              navigator.geolocation.getCurrentPosition(() => { });
            } else if (result.state === "denied") {
              setModalText(language === 'en' ? 'Please Enable Location Permission to use this service' : 'الرجاء تفعيل خاصية الموقع');
              reject(null);
            }
            result.onchange = async function () {
              if (result.state === "granted") {
                const position = await getPosition();
                resolve(position.coords);
              } else if (result.state === "denied") {
                setModalText(language === 'en' ? 'Please Enable Location Permission to use this service' : 'الرجاء تفعيل خاصية الموقع');
                reject(null);
              }
            };
          });
      } else {
        setModalText(language === 'en' ? "Sorry, Location Service Not available!" : 'عذراً ! خاصية الموقع غير متوفرة');
        reject(null);
      }
    })
  }

  const getPosition = async () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    })
  }

  const findNearestLocation = async () => {
    let locationInfo;
    try {
      locationInfo = await getLocationPermission();
    } catch (err) {
      setModalVisible(true);
      return;
    }
    const { latitude: myLatitude, longitude: myLongitude } = locationInfo;

    const nearest = findNearest({ latitude: myLatitude, longitude: myLongitude }, [
      { latitude: 31.9518417, longitude: 35.8992056 },
      { latitude: 31.9535976, longitude: 35.8966029 },
      { latitude: 31.9497044, longitude: 35.9031626 },
      { latitude: 31.9781062, longitude: 35.8998042 },
      { latitude: 31.9598923, longitude: 35.8767252 },
      { latitude: 31.9551916, longitude: 35.8422347 },
      { latitude: 31.171236, longitude: 35.7093553 }
    ]);

    const nearestLatLng = `${nearest.latitude},${nearest.longitude}`
    const nearestBranch = data.locationDetails.filter(labLocation => labLocation.location.includes(nearestLatLng))[0];

    window.open(nearestBranch.location, '_blank');
  }

  return (
    <Box>
      <NavBar />
      <Container style={{ marginTop: '5%' }}>
        <Typography data-aos="fade-down" style={{ textAlign: language === 'en' ? 'left' : 'right' }} className={classes.title}>
          {language === 'en' ? 'Our Locations' : 'فروعنا'}
        </Typography>
        <Divider />
        <Box data-aos="flip-left" style={{ display: 'flex', justifyContent: 'center', marginBlock: '1%' }}>
          <Button
            className={classes.nearstButton}
            variant="contained"
            size="medium"
            startIcon={<LocationOn style={{ color: 'white' }} />}
            onClick={findNearestLocation}
          >
            {language === 'en' ? 'Nearest Branch' : 'الفرع الأقرب لك'}
          </Button>
        </Box>
        <Grid style={{ direction: language === 'en' ? 'ltr' : 'rtl' }} container>
          {data.locationDetails.map(({ arabicName, englishName, englishStName, arabicStName, phoneNumber, location }) => (
            <Grid data-aos="fade-left" style={{ display: 'flex', justifyContent: 'center' }} item md={6} xs={12}>
              <Box className={classes.buttonContainer}>
                <Box className={classes.buttonContentContainer}>
                  <img
                    className={classes.img}
                    alt="location"
                    src={require('../images/location.png')}
                  />
                  <Typography className={classes.locationTitle}>
                    {language === 'en' ? englishName : arabicName}
                  </Typography>
                </Box>
                <Typography className={classes.stName}>
                  {language === 'en' ? englishStName : arabicStName}
                </Typography>
                <Divider className={classes.divider} />
                <Box className={classes.contentContainer}>
                  <Box style={{ display: 'flex' }}>
                    <PhoneInTalk className={classes.icon} />
                    <Typography className={classes.locationDetails}>
                      {language === 'en' ? `Phone Number: ${phoneNumber}` : `رقم الهاتف: ${phoneNumber}`}
                    </Typography>
                  </Box>
                  <Box style={{ display: 'flex' }}>
                    <Explore className={classes.icon} />
                    <Typography className={classes.locationDetails}>
                      {language === 'en' ? `location:` : `الموقع:`}
                    </Typography>
                    <Button
                      className={classes.locationButton}
                      variant="contained"
                      size="small"
                      href={location}
                      target="_blank"
                    >
                      {language === 'en' ? 'Press Here' : 'اضغط هنا'}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}

        </Grid>
        <CustomModal
          title={language === 'en' ? "Warning!" : "!رسالة تنبيه"}
          content={modalText}
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          showConfirmButton={false}
          loading={false}
        />
      </Container>
      <Footer />
    </Box>
  );
};

export const useStyles = makeStyles({
  title: {
    textAlign: ({ language }) => (language === 'en' ? 'left' : 'right'),
    fontSize: '1.7em',
    color: appColor
  },
  nearstButton: {
    background: 'linear-gradient(#1b2551, #acafaf)',
    //height: isMobile || isTablet ? windowHeight / 5.5 : windowHeight / 5,
    color: 'white',
    alignSelf: 'center'
  },
  buttonContainer: {
    width: '90%',
    //height: isMobile || isTablet ? windowHeight / 5.5 : windowHeight / 5,
    padding: '5%',
    justifyContent: 'center',
    background: 'linear-gradient(#1b2551, #acafaf)',
    direction: ({ language }) => (language === 'en' ? 'ltr' : 'rtl'),
    marginBlock: '3%',
    display: 'flex',
    flexDirection: 'column',
    //alignItems: 'center',
    borderRadius: 20,
    // '&:hover': {
    //     cursor: 'pointer'
    // }
  },
  buttonContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    //marginTop: '3%'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '3%',
    marginInline: '5%'
  },
  locationTitle: {
    color: 'white',
    fontSize: '1.5em'
  },
  stName: {
    color: 'white',
    fontSize: '0.9em',
    marginInline: '9%',
    marginTop: '0.5%'
  },
  divider: {
    border: '1px solid #ffffff',
    width: '95%',
    alignSelf: 'center',
    marginTop: '1%'
  },
  locationDetails: {
    color: 'white',
    textAlign: ({ language }) => (language === 'en' ? 'left' : 'right'),
    fontSize: '1.1em',
    marginBottom: '1%'
  },
  locationButton: {
    backgroundColor: 'white',
    color: appColor,
    marginInline: '2%',
    fontWeight: 'bold',
    '&:hover': {
      color: appColor
    }
  },
  img: {
    objectFit: 'contain',
    width: '5%',
    marginInline: '2%'
  },
  icon: {
    color: 'white',
    marginInline: '1%',
    fontSize: '1.3em'
  }
});

export default LocationScreen;