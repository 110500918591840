import React, { useState, useEffect, useContext } from 'react';
import { windowHeight, windowWidth } from '../common';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  Divider,
  Box,
  Button,
  CardActions,
  CardContent,
  CardMedia,
  Card,
  CardActionArea
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Aos from 'aos';
import 'aos/dist/aos.css';
import NavBar from '../components/Navbar';
import Slider from '../components/Slider';
import { Context as LanguageContext } from '../context/LanguageContext';
import { Context as NewsContext } from '../context/NewsContext';
import { Context as BlogsContext } from '../context/BlogsContext';
import { Context as BannerContext } from '../context/BannerContext';
import { Context as PopupContext } from '../context/PopupContext';
import Footer from '../components/Footer';
import { useStyles } from '../styles/homeScreenStyle'
import PopUpModal from '../components/PopUpModal';
import axios from 'axios';

const AWS_URL = 'https://smartlabs-bucket.s3.eu-central-1.amazonaws.com/';

const HomeScreen = () => {
  const [showPopUp, setShowPopUp] = useState(true);
  const [videoPath, setVideoPath] = useState('');

  const {
    state: { language },
  } = useContext(LanguageContext);
  const { fetchNews, state: { news } } = useContext(NewsContext);
  const { fetchBlogs, state: { blogs } } = useContext(BlogsContext);
  const { fetchBannerImages, state: { images: bannerImages } } = useContext(BannerContext);
  const { state: { popup }, fetchPopup } = useContext(PopupContext);

  const props = {
    language,
    color: 'red'
  };

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      await fetchNews();
      await fetchBlogs();
      await fetchBannerImages();
      await fetchPopup();
      // const res = await axios.get("/api/video");
      // setVideoPath(res.data.path);
    }

    Aos.init({
      duration: 2000
    });
    fetchData();
  }, []);


  let banner;
  if (bannerImages.length) {
    banner = bannerImages.map(image => AWS_URL + image.url);
  } else {
    banner = [require('../images/White.png')]
  }

  const classes = useStyles(props);
  return (
    <div>
      <NavBar />
      <Slider
        images={banner}
        carouselImage={classes.carouselImage}
        carouselImageMobile={isMobileOnly ? classes.carouselImageMobile : ''}
        carouselimg={isMobileOnly ? classes.carouselimg : classes.test}
      />
      <Container>
        <section id="about">
          <Grid container style={{ marginTop: '3%' }}>
            <Grid data-aos="fade-up" md={6} className={classes.gridStyle}>
              <img
                src={require('../images/about.png')}
                alt="about"
                className={classes.aboutImage}
              />
            </Grid>
            <Grid md={6} data-aos="fade-left">
              <Typography
                variant='h4'
                style={{
                  marginTop: '3%',
                  marginBottom: '2%',
                  textAlign: language === 'en' ? 'left' : 'right'
                }}
              >
                {language === 'en' ? 'Who Are We?' : 'من نحن ؟'}
              </Typography>
              <Divider style={{ border: '1px solid gray' }} />
              <Typography style={{ direction: language === 'en' ? 'ltr' : 'rtl' }} variant='subtitle1' className={classes.content}>
                {language === 'en' ?
                  `Since our establishment in 2010, we believed in harnessing science for human good, hence we walked miles toward strengthening and empowering the laboratory sector in The Middle East and MENA region by employing state-of-the-art diagnostic technologies and investing in professional and qualified technicians.
          
                                    Smart Labs group is dedicated to comprehensive, high-quality, rapid-response testing, and the integration of these technologies into patient care.
          
                                    We provide valuable information that is critical for the categorization, assessment and treatment of patients. This leads to confident treatment decisions made by doctors and more efficient ways to determine and employ therapies that makes patient care possible.`
                  : `منذ تأسيسنا في عام 2010 ، اّمنا بتسخير العلم لصالح الإنسان ولذلك قطعنا أميالاً نحو تقوية وتمكين قطاع الطب المخبري والفحوصات في منطقة الشرق الأوسط وشمال إفريقيا, وذلك من خلال استخدام أحدث تقنيات التحليل والاستثمار في فنيين وإخصائيين من أكفأ المهارات, وقمنا بتكريس وتقديم فحوصات خاصة شاملة الجودة العالية وسرعة الاستجابة ، وتم دمج هذه التقنيات في سبل رعاية المرضى.

                                    تؤثر دقة وصحة التحاليل والفحوصات التي تقوم بها مجموعة سمارت لاب, بتصنيف المرضى وتقييمهم بشكل أفضل من أجل العلاج ويؤثر هذا إيجابيا على اتخاذ الأطباء للقرارات العلاجية وإلى اتباع طرق ونهج أكثر فاعلية لتحديد واستخدام العلاجات التي تجعل رعاية المرضى ممكنة.`
                }
              </Typography>
            </Grid>
          </Grid>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <video src={require('../uploads/smart-video.mp4')} width="750" height="500" controls autoPlay muted loop style={{ marginBlock: '2%' }} />
          </Box>
        </section>
        <Box data-aos="fade-down" className={classes.serviceTitleContainer}>
          <Typography variant='h4' className={classes.serviceTitle}>
            {language === 'en' ? 'Our Services' : 'خدماتنا'}
          </Typography>
          <Divider variant='middle' style={{ border: '1px solid gray', width: '20%' }} />
        </Box>
        <Grid container>
          <Grid data-aos="zoom-in-up" item md={4} xs={12}>
            <Box
              style={{
                marginTop: '5%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <a
                href='/packages'
                style={{
                  textDecorationLine: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignSelf: 'center',
                  justifyContent: 'center',
                  //width: '50%'
                }}>
                <img
                  src={require('../images/package.jpeg')}
                  alt="service"
                  style={{
                    objectFit: 'contain',
                    height: windowHeight / 2.5,
                    width: isMobile ? '80%' : windowWidth / 5.5,
                    alignSelf: 'center'
                  }}
                />
                <Typography className={classes.service} variant='h5'>
                  {language === 'en' ? 'Packages' : 'الباقات'}
                </Typography>
              </a>
            </Box>
          </Grid>
          <Grid data-aos="zoom-in-up" item md={4} xs={12}>
            <Box
              style={{
                marginTop: '5%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <a
                href='/results'
                style={{
                  textDecorationLine: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignSelf: 'center',
                  justifyContent: 'center',
                  //width: '50%'
                }}>
                <img
                  src={require('../images/results.png')}
                  alt="service"
                  className={classes.serviceImage}
                />
                <Typography className={classes.service} variant='h5'>
                  {language === 'en' ? 'Your results' : 'نتائج الفحوصات'}
                </Typography>
              </a>
            </Box>
          </Grid>
          <Grid data-aos="zoom-in-up" item md={4} xs={12}>
            <Box
              style={{
                marginTop: '5%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <a
                href='/booking'
                style={{
                  textDecorationLine: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignSelf: 'center',
                  justifyContent: 'center',
                  //width: '50%'
                }}>
                <img
                  src={require('../images/booking.png')}
                  alt="service"
                  className={classes.serviceImage}
                />
                <Typography className={classes.service} variant='h5'>
                  {language === 'en' ? 'Booking an appointment' : 'احجز موعدك'}
                </Typography>
              </a>
            </Box>
          </Grid>
        </Grid>
        {/* <Box style={{
                    marginTop: '5%', display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
                data-aos="zoom-in-up"
                >
                    <a
                        href='/booking'
                        style={{
                            textDecorationLine: 'none',
                            display: 'flex',
                            flexDirection: 'column',
                            alignSelf: 'center',
                            justifyContent: 'center',
                            //width: '50%'
                        }}>
                        <img
                            src={require('../images/booking.png')}
                            alt="service"
                            className={classes.serviceImage}
                        />
                        <Typography className={classes.service} variant='h5'>
                            {language === 'en' ? 'Booking an appointment' : 'احجز موعدك'}
                        </Typography>
                    </a>
                </Box> */}
      </Container>
      {isMobileOnly ?
        <Container>
          <Grid data-aos="fade-up" md={12}>
            <Typography variant='h5' className={classes.appTitleMob}>
              Your Time Is Important...
            </Typography>
            <Typography variant='h5' className={classes.appSubTitleMob}>
              Download the app to save your time...
            </Typography>
          </Grid>
          <Grid data-aos="flip-left" style={{ display: 'flex', justifyContent: 'center' }} md={12}>
            <img
              src={require('../images/smart_app.png')}
              alt='smart app'
              className={classes.appImageMob}
            />
          </Grid>
          <Grid data-aos="flip-left" container>
            <Grid item style={{ marginBlock: '5%', display: 'flex', justifyContent: 'center' }} xs={6}>
              <a
                href="https://play.google.com/store/apps/details?id=com.mate.smartlab"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('../images/google_play.png')}
                  alt='google'
                  className={classes.googleStoreMob}
                />
              </a>
            </Grid>
            <Grid item style={{ marginBlock: '5%', display: 'flex', justifyContent: 'center' }} xs={6}>
              <a href="https://apps.apple.com/jo/app/4th-generation-smart-lab/id6449221404"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('../images/app_store.png')}
                  alt='apple'
                  className={classes.appStoreMob}
                />
              </a>
            </Grid>
          </Grid>
        </Container>
        :
        <div style={{ marginTop: '5%', height: windowHeight / 2, backgroundImage: `url(${require("../images/background.jpg")})` }}>
          {/* <img
                    src={require('../images/background.jpg')}
                    alt='background'
                    className={classes.background}
                /> */}
          <Container style={{ direction: language === 'en' ? 'ltr' : 'rtl' }}>
            <Grid container>
              <Grid data-aos="fade-up" item md={6}>
                <Typography variant='h5' className={classes.appTitle}>
                  {language === 'en' ? 'Your Time Is Important...' : 'وقتك مهم ويهمنا أيضاً...'}
                </Typography>
                <Typography variant='h5' className={classes.appSubTitle}>
                  {language === 'en' ? 'Download the app to save your time...' : 'حمّل تطبيق مختبرات سمارت لاب الآن'}
                </Typography>
              </Grid>
              <Grid data-aos="flip-left" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5%' }} item md={6}>
                <img
                  src={require('../images/smart_app.png')}
                  alt='smart app'
                  className={classes.appImage}
                />
              </Grid>
            </Grid>
            <Box data-aos="fade-left" style={{ display: 'flex' }}>
              <a
                href="https://play.google.com/store/apps/details?id=com.mate.smartlab"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('../images/google_play.png')}
                  alt='google'
                  className={classes.googleStore}
                />
              </a>
              <a href="https://apps.apple.com/jo/app/4th-generation-smart-lab/id6449221404"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('../images/app_store.png')}
                  alt='apple'
                  className={classes.appStore}
                />
              </a>
            </Box>
          </Container>
        </div>
      }
      <div>
        <Typography data-aos="fade-down" style={{ textAlign: language === 'en' ? 'left' : 'right' }} className={classes.newsTitle} variant='h4'>
          {language === 'en' ? 'Latest News...' : '...آخر الأخبار'}
        </Typography>
        <div style={{ direction: language === 'en' ? 'ltr' : 'rtl' }} data-aos="fade-right" className={classes.root}>
          {news.slice(0, 3).map(
            ({ imageUrl, arabicTitle, englishTitle, arabicDescription, englishDescription }, index) => (
              <Grid item md={4} xs={6} className={classes.standImageContainer}>
                <Card className={classes.cardContainer}>
                  <CardActionArea
                    onClick={() => navigate('/latest_news', {
                      state: {
                        selectedIndex: index
                      }
                    })}
                  >
                    <CardMedia
                      className={classes.media}
                      image={AWS_URL + imageUrl}
                    />
                    <CardContent style={{ textAlign: language === 'en' ? 'left' : 'right' }} className={classes.cardContent}>
                      <Typography gutterBottom variant="h5" component="h2">
                        {language === 'en' ? englishTitle : arabicTitle}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {language === 'en' ?
                          <div dangerouslySetInnerHTML={{ __html: englishDescription.split('\n')[0].slice(0, 50) + '...' }} />
                          : <div dangerouslySetInnerHTML={{ __html: arabicDescription.split('\n')[0].slice(0, 50) + '...' }} />}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="small"
                      className={classes.learnText}
                      variant="contained"
                      onClick={() => navigate('/latest_news', {
                        state: {
                          selectedIndex: index
                        }
                      })}
                    >
                      {language === 'en' ? 'Learn more...' : 'المزيد...'}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            )
          )}
        </div>
        <Box style={{ display: 'flex', justifyContent: 'center', marginBottom: '3%' }}>
          <Button
            className={classes.seeMoreButton}
            variant='contained'
            onClick={() => navigate('/latest_news', {
              state: {
                selectedIndex: 0
              }
            })}
          >
            {language === 'en' ? 'See more...' : '...للمزيد اضغط هنا'}
          </Button>
        </Box>
        <Typography data-aos="fade-down" style={{ textAlign: language === 'en' ? 'left' : 'right' }} className={classes.newsTitle} variant='h4'>
          {language === 'en' ? 'Blogs...' : '...المقالات'}
        </Typography>
        <div style={{ direction: language === 'en' ? 'ltr' : 'rtl' }} data-aos="fade-right" className={classes.root}>
          {blogs.slice(0, 3).map(
            ({ imageUrl, arabicTitle, englishTitle, arabicDescription, englishDescription }, index) => (
              <Grid item md={4} xs={6} className={classes.standImageContainer}>
                <Card className={classes.cardContainer}>
                  <CardActionArea
                    onClick={() => navigate('/blogs', {
                      state: {
                        selectedIndex: index
                      }
                    })}
                  >
                    <CardMedia
                      className={classes.media}
                      image={AWS_URL + imageUrl}
                    />
                    <CardContent style={{ textAlign: language === 'en' ? 'left' : 'right' }} className={classes.cardContent}>
                      <Typography gutterBottom variant="h5" component="h2">
                        {language === 'en' ? englishTitle : arabicTitle}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {language === 'en' ?
                          <div dangerouslySetInnerHTML={{ __html: englishDescription.split('\n')[0].slice(0, 50) + '...' }} />
                          : <div dangerouslySetInnerHTML={{ __html: arabicDescription.split('\n')[0].slice(0, 50) + '...' }} />}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="small"
                      className={classes.learnText}
                      variant="contained"
                      onClick={() => navigate('/blogs', {
                        state: {
                          selectedIndex: index
                        }
                      })}
                    >
                      {language === 'en' ? 'Learn more...' : 'المزيد...'}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            )
          )}
        </div>
        <Box style={{ display: 'flex', justifyContent: 'center', marginBottom: '3%' }}>
          <Button
            className={classes.seeMoreButton}
            variant='contained'
            onClick={() => navigate('/blogs', {
              state: {
                selectedIndex: 0
              }
            })}
          >
            {language === 'en' ? 'See more...' : '...للمزيد اضغط هنا'}
          </Button>
        </Box>
        <Footer />
        {popup.length ? <PopUpModal
          visible={showPopUp && popup.length}
          onClose={() => setShowPopUp(false)}
          content={popup[0].content}
          img={AWS_URL + popup[0].imageUrl}
        /> : null}
      </div>
    </div>
  );
};

export default HomeScreen;
