import React from 'react';
import { Carousel } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { windowHeight } from '../common';

const windowWidth = window.innerWidth;

const Slider = ({
  images,
  carouselStyle,
  showShopNowButton,
  carouselMobile,
  carouselImage,
  carouselimg,
  carouselImageMobile
}) => {
  return (
    <Carousel
      className={isMobileOnly ? carouselMobile : carouselStyle}
      fade
    >
      {images.map(image => (
        <Carousel.Item
          className={isMobileOnly || isTablet ? carouselImageMobile : carouselImage}
          style={{ objectFit: 'cover' }}
        >
          <img
            className={`d-block w-100 ${carouselimg}`}
            src={image}
            alt="First slide"
            style={{ objectFit: isMobileOnly || isTablet ? 'contain' : 'fill', height: '100%' }}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default Slider;
