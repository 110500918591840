import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Button,
  Container,
  TextField,
  Fade,
  Box,
  IconButton
} from '@material-ui/core';
import { Delete } from '@material-ui/icons'
import { isMobile } from 'react-device-detect';
import CustomModal from './components/CustomModal';
import { Context as AuthContext } from '../context/AuthContext';
import { windowHeight } from '../common';
import axios from 'axios';

const AWS_URL = 'https://smartlabs-bucket.s3.eu-central-1.amazonaws.com/';

const AdminVideo = () => {
  const [modalText, setModalText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [video, setVideo] = useState(null);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const navigate = useNavigate();

  const {
    state: { token },
    tryLocalSignin
  } = useContext(AuthContext);

  useEffect(() => {
    if (!token) {
      tryLocalSignin(navigate, '/admin_video');
    }

  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!video) return;

    setLoading(true);

    const formData = new FormData();
    formData.append('video', video);

    const res = await axios.post('/api/video', formData);

    if (res.error) {
      setModalText('There is a problem in uplading the video!');
    } else {
      setModalText('Video uploaded Successfully');
    }

    setShowConfirmButton(false);
    setModalVisible(true);
    setLoading(false);
  }

  if (!token) {
    return null;
  }

  return (
    <Container>
      <form enctype="multipart/form-data">
        {/* action="/api/video" method="POST" */}
        <Typography className={classes.title}>Upload Video</Typography>
        <Divider />
        <Grid className={classes.container} container>
          <Grid xs={12}>
            <Typography className={classes.label}>Video</Typography>
            <Button
              className={isMobile ? classes.uploadButton : ''}
              variant="contained"
              component="label"
              color="primary"
            >
              Upload Video
              <input
                name="video"
                type="file"
                accept=".mp4, .avi, .mkv"
                id="files"
                onChange={event => {
                  event.preventDefault();
                  setVideo(event.target.files[0])
                }}
                hidden
              />
            </Button>
            <Typography className={classes.uploadText}>
              {video
                ? 'Video selected'
                : 'Please Upload the video'}
            </Typography>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="secondary"
          className={classes.submitButton}
          //disabled={popup.length}
          onClick={() => {
            setModalText('Do you want to Submit this Video?');
            setShowConfirmButton(true);
            setModalVisible(true);
          }}
        >
          Submit
        </Button>
        <CustomModal
          title="Warning!"
          content={modalText}
          visible={modalVisible}
          onCancel={() => {
            setModalVisible(false);
          }}
          onConfirm={handleSubmit}
          showConfirmButton={showConfirmButton}
          loading={loading}
        />
      </form>
    </Container>
  );
};

const useStyles = makeStyles({
  title: {
    fontSize: '1.6em',
    marginBlock: '1%'
  },
  container: {
    marginTop: isMobile ? '5%' : '2%'
  },
  label: {
    fontSize: '1.4em',
    marginBottom: '1%'
  },
  textField: {
    marginBottom: isMobile ? '5%' : '3%',
    width: isMobile ? '100%' : '70%'
  },
  submitButton: {
    float: 'right',
    width: isMobile ? '35%' : '10%',
    //marginTop: '1%',
    marginBottom: '2%'
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  image: {
    objectFit: 'contain',
    height: windowHeight / 3.5
  },
  popUpContent: {
    marginBlock: '3%',
    textAlign: 'center'
  }
});

export default AdminVideo;