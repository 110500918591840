import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Button,
  Container,
  TextField,
  Fade
} from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import CustomModal from '../components/CustomModal';
import { Context as PackagesContext } from '../../context/PackagesContext';
import { Context as AuthContext } from '../../context/AuthContext';
import { windowWidth } from '../../common';
import JoditEditor from 'jodit-react';

const AddPackages = () => {
  const [arabicName, setArabicName] = useState('');
  const [englishName, setEnglishName] = useState('');
  const [image, setImage] = useState(null);
  const [arabicDescription, setArabicDescription] = useState('');
  const [englishDescription, setEnglishDescription] = useState('');

  const [modalText, setModalText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [resetPhoto, setResetPhoto] = useState(false);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const navigate = useNavigate();
  const editor = useRef(null);

  const {
    state: { token },
    tryLocalSignin
  } = useContext(AuthContext);
  const { uploadPackages } = useContext(PackagesContext);

  useEffect(() => {
    tryLocalSignin(navigate, '/add_packages');
  }, []);

  const handleSubmit = async () => {
    setLoading(true);

    const res = await uploadPackages(arabicName, englishName, image, arabicDescription, englishDescription);

    if (res.error) {
      setModalText('There is a problem in uploading the package!');
    } else {
      setModalText('Package uploaded successfully');
      setImage(null);
      setArabicName('');
      setEnglishName('');
      setArabicDescription('');
      setEnglishDescription('');
    }

    setShowConfirmButton(false);
    setModalVisible(true);
    setLoading(false);
  };

  const validateImagesSizes = async event => {
    event.preventDefault();

    Object.values(event.target.files).forEach(imgFile => {
      if (imgFile.size > 1048576) {
        setModalText('There are images that exceed 1MB limit size');
        setModalVisible(true);
        setShowConfirmButton(false);
        setResetPhoto(true);
        return;
      }
    });

    setImage(event.target.files[0]);
  };

  if (!token) {
    return null;
  }

  return (
    <div>
      <Container style={{ marginTop: '1%' }}>
        <Fade timeout={1000} in={true}>
          <div>
            <Typography className={classes.title}>Add Packages</Typography>
            <Divider />
            <Grid className={classes.container} container>
              <Grid md={4} xs={12}>
                <TextField
                  value={englishName}
                  onChange={e => setEnglishName(e.target.value)}
                  placeholder="English Package Title"
                  variant="outlined"
                  className={classes.textField}
                />
              </Grid>
              <Grid md={4} xs={12}>
                <TextField
                  value={arabicName}
                  onChange={e => setArabicName(e.target.value)}
                  placeholder="Arabic Package Title"
                  variant="outlined"
                  className={classes.textField}
                />
              </Grid>
              <Grid direction="row" md={4} xs={12}>
                <Button
                  className={isMobile ? classes.uploadButton : ''}
                  variant="contained"
                  component="label"
                  color="primary"
                >
                  Upload Photo
                  <input
                    name="photos"
                    type="file"
                    id="files"
                    onChange={event => validateImagesSizes(event)}
                    hidden
                  />
                </Button>
                <Typography variant="h6">
                  {image ? 'Image Selected' : 'Please Select Photo'}
                </Typography>
              </Grid>
              <Grid item md={12}>
                <Typography className={classes.title}>English Description</Typography>
                <JoditEditor
                  value={englishDescription}
                  onChange={e => setEnglishDescription(e)}
                  ref={editor}
                />
              </Grid>
              <Grid item md={12}>
                <Typography className={classes.title}>Arabic Description</Typography>
                <JoditEditor
                  value={arabicDescription}
                  onChange={e => setArabicDescription(e)}
                  ref={editor}
                />
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="secondary"
              className={classes.submitButton}
              onClick={() => {
                if (!image || !arabicName || !englishName || !arabicDescription || !englishDescription) {
                  return;
                }

                setModalText('Do you want to submit this?');
                setShowConfirmButton(true);
                setModalVisible(true);
                setResetPhoto(false);
              }}
            >
              Submit
            </Button>
            <CustomModal
              title="Warning!"
              content={modalText}
              visible={modalVisible}
              onCancel={() => {
                setModalVisible(false);
                if (resetPhoto) setImage(null);
              }}
              onConfirm={handleSubmit}
              showConfirmButton={showConfirmButton}
              loading={loading}
            />
          </div>
        </Fade>
      </Container>
    </div>
  );
};

const useStyles = makeStyles({
  title: {
    fontSize: '1.6em',
    marginBlock: '1%'
  },
  container: {
    marginTop: isMobile ? '5%' : '2%'
  },
  textField: {
    marginBottom: '5%'
  },
  boxField: {
    marginTop: '2%',
    marginBottom: '2%',
    width: windowWidth / 1.5
  },
  uploadButton: {
    marginTop: '5%'
  },
  submitButton: {
    float: 'right',
    width: isMobile ? '35%' : '10%',
    marginTop: '5%',
    marginBottom: '2%'
  }
});

export default AddPackages;
