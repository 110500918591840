import React, { useEffect, useContext } from 'react';
import { Box, Container, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { Context as LanguageContext } from '../context/LanguageContext';
import { appColor, windowHeight, windowWidth } from '../common';
import Aos from 'aos';
import 'aos/dist/aos.css';
import NavBar from '../components/Navbar';
import { useLocation } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import Footer from '../components/Footer';

const NewsDetails = () => {
  const {
    state: { language },
  } = useContext(LanguageContext);

  const props = {
    language
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({
      duration: 2000
    });
  })

  const location = useLocation();
  const { arabicTitle, englishTitle, arabicDescription, englishDescription, imageUrl } = location.state;

  const classes = useStyles(props);
  return (
    <Box>
      <NavBar />
      <Container style={{ marginTop: '5%', minHeight: windowHeight / 1.5, marginBottom: '1%' }}>
        <Box style={{ direction: language === 'en' ? 'ltr' : 'rtl' }}>
          <img
            src={imageUrl}
            alt="news_photo"
            className={classes.img}
          />
          <Typography style={{ textAlign: language === 'en' ? 'left' : 'right' }} className={classes.title}>
            {language === 'en' ? englishTitle : arabicTitle}
          </Typography>
          <Divider style={{ marginBottom: '1%' }} />
          <Typography style={{ textAlign: language === 'en' ? 'left' : 'right' }} className={classes.content}>
            {language === 'en' ? <div dangerouslySetInnerHTML={{ __html: englishDescription }} /> : <div dangerouslySetInnerHTML={{ __html: arabicDescription }} />}
          </Typography>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export const useStyles = makeStyles({
  img: {
    objectFit: 'contain',
    width: isMobileOnly ? '100%' : windowWidth / 3.5,
    borderRadius: 10,
    float: 'left',
    marginRight: '1%',
    marginBottom: '1%'
  },
  title: {
    fontSize: '1.7em',
    color: appColor,
  },
  content: {
    display: 'inline',
    marginBlock: '3%',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word'
  },
})

export default NewsDetails;