import React, { useContext, useEffect } from 'react';
import {
  Container,
  Typography,
  Divider,
  Box,
  makeStyles,
} from '@material-ui/core';
import Aos from 'aos';
import 'aos/dist/aos.css';
import NavBar from "../components/Navbar";
import { Context as LanguageContext } from '../context/LanguageContext';
import { appColor } from '../common';

const AboutScreen = () => {
  const classes = useStyles();
  
  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({
      duration: 2000
    });
  })
  
  const {
    state: { language },
  } = useContext(LanguageContext);
  return (
    <Box>
      <NavBar />
      <Container data-aos={language === 'en' ? "fade-right" : 'fade-left'} style={{direction: language === 'en' ? 'ltr': 'rtl', marginTop: '5%'}}>
        <Typography className={classes.title}>
          {language === 'en' ? 'About us' : 'نبذة عنا'}
        </Typography>
        <Divider />
        <Typography className={classes.content}>
          {language === 'en' ?
          `Since our establishment in 2010, we believed in harnessing science for human good, hence we walked miles toward strengthening and empowering the laboratory sector in The Middle East and MENA region by employing state-of-the-art diagnostic technologies and investing in professional and qualified technicians.
          
          Smart Labs group is dedicated to comprehensive, high-quality, rapid-response testing, and the integration of these technologies into patient care.
          
          We provide valuable information that is critical for the categorization, assessment and treatment of patients. This leads to confident treatment decisions made by doctors and more efficient ways to determine and employ therapies that makes patient care possible.`
          : `منذ تأسيسنا في عام 2010 ، اّمنا بتسخير العلم لصالح الإنسان ولذلك قطعنا أميالاً نحو تقوية وتمكين قطاع الطب المخبري والفحوصات في منطقة الشرق الأوسط وشمال إفريقيا, وذلك من خلال استخدام أحدث تقنيات التحليل والاستثمار في فنيين وإخصائيين من أكفأ المهارات, وقمنا بتكريس وتقديم فحوصات خاصة شاملة الجودة العالية وسرعة الاستجابة ، وتم دمج هذه التقنيات في سبل رعاية المرضى.

          تؤثر دقة وصحة التحاليل والفحوصات التي تقوم بها مجموعة سمارت لاب, بتصنيف المرضى وتقييمهم بشكل أفضل من أجل العلاج ويؤثر هذا إيجابيا على اتخاذ الأطباء للقرارات العلاجية وإلى اتباع طرق ونهج أكثر فاعلية لتحديد واستخدام العلاجات التي تجعل رعاية المرضى ممكنة` 
        }
        </Typography>
      </Container>
    </Box>
  );
};

export const useStyles = makeStyles({
  title: {
    fontSize: '1.7em',
    color: appColor
  },
  content: {
    fontSize: '1.2em',
    marginTop: '1%',
    whiteSpace: 'pre-line',
    overflow: 'hidden'
  }
})


export default AboutScreen;