import React, { useContext } from 'react';
import {
  makeStyles,
  Typography,
  Box,
  Grid,
  Container
} from '@material-ui/core';
import '../css/Footer.css';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { Context as LanguageContext } from '../context/LanguageContext';

const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;

const Footer = ({ position }) => {
  const {
    state: { language },
  } = useContext(LanguageContext);

  const props = {
    language, position
  };

  const classes = useStyles(props);
  return (
    <section id="contact">
      <footer>
        <Box style={{ direction: language === 'en' ? 'ltr' : 'rtl', position: position }} className={classes.footerContainer}>
          <Container>
            <Grid container>
              <Grid md={4} xs={12}>
                <Typography className={classes.contactTitle}>
                  {language === 'en' ? 'Contact Us' : 'تواصل معنا'}
                </Typography>
                <Typography className={classes.contactDetails}>
                  {language === 'en' ? 'Phone: 06 465 4445' : 'الهاتف : 4445 465 06'}
                </Typography>
                <Typography className={classes.contactDetails}>
                  {language === 'en' ? 'Mobile: +962 796628283' : 'موبايل : 796628283 962+'}
                </Typography>
                <Typography className={classes.contactDetails}>
                  {language === 'en' ? 'E-mail: info@smartlabsgroup.com' : 'إيميل : info@smartlabsgroup.com'}
                </Typography>
              </Grid>
              <Grid md={4} xs={12}>
                <Typography className={classes.contactTitle}>
                  {language === 'en' ? 'Follow us' : 'تابعنا على منصات التواصل'}
                </Typography>
                <a
                  target="_blank"
                  href="https://web.facebook.com/smartlabsgroup"
                  className="fab fa-facebook"
                  rel="noopener noreferrer"
                >
                  {' '}
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/smartlabs.group/"
                  className="fab fa-instagram"
                  rel="noopener noreferrer"
                >
                  {' '}
                </a>
              </Grid>
              <Grid md={4} xs={12}>
                <Typography className={classes.contactTitle}>
                  {language === 'en' ? 'Download Our App on' : 'حمّل التطبيق'}
                </Typography>
                <Grid container>
                  <Grid md={6} xs={12}>
                    <a
                      href='https://apps.apple.com/jo/app/4th-generation-smart-lab/id6449221404'
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require('../images/app_store.png')}
                        alt="app store"
                        className={classes.appStore}
                      />
                    </a>
                  </Grid>
                  <Grid md={6} xs={12}>
                    <a
                      href='https://play.google.com/store/apps/details?id=com.mate.smartlab'
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require('../images/google_play.png')}
                        alt="app store"
                        className={classes.googleStore}
                      />
                    </a>
                  </Grid>
                </Grid>
              </Grid>
              <Grid md={4} xs={12}>
                <Typography className={classes.poweredText}>
                  Powered by{' '}
                  <a
                    target="_blank"
                    href="https://www.mate-co.com"
                    rel="noopener noreferrer"
                    className={classes.mateLink}
                  >
                    Mate-co
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </footer>
    </section>
  );
};

const useStyles = makeStyles({
  facebookIcon: {
    fontSize: '2.5em',
    color: '#3b5998'
  },
  instagramIcon: {
    fontSize: '2.5em',
    color: '#fb3958'
  },
  footerContainer: {
    background: 'linear-gradient(to right, #1b2551, #acafaf)',
    //height: isMobileOnly ? '' : windowHeight / 4,
    width: '100%',
    bottom: 0,
  },
  contactTitle: {
    color: 'white',
    fontSize: '1.5em',
    marginTop: '2%'
  },
  contactDetails: {
    color: 'white',
    fontSize: '1em',
    marginTop: '1%'
  },
  poweredText: {
    color: 'white',
    fontSize: '1em',
    marginTop: '4%',
    marginBottom: '4%'
  },
  mateLink: {
    color: 'white'
  },
  appStore: {
    width: isMobileOnly ? '50%' : isTablet ? windowWidth / 7 : '80%',
    objectFit: 'contain',
    marginBottom: '2%'
  },
  googleStore: {
    width: isMobileOnly ? '50%' : isTablet ? windowWidth / 7 : '80%',
    objectFit: 'contain',
    marginTop: isMobileOnly ? '2%' : 0
  },
  appGalleryStore: {
    width: isMobileOnly ? '50%' : '90%',
    objectFit: 'contain',
    marginTop: isMobileOnly ? '2%' : 0,
    marginBottom: isMobileOnly ? '2%' : 0
  }
});

export default Footer;
