import React, { useState, useContext, useEffect } from 'react';
import { appColor } from '../common';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { makeStyles, InputBase, alpha, Box, Hidden } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import '../css/Navbar.css';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { Context as LanguageContext } from '../context/LanguageContext';
import { Context as CategoryContext } from '../context/CategoryContext';
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";

const AWS_URL = 'https://smartlabs-bucket.s3.eu-central-1.amazonaws.com/';

const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;

const NavBar = () => {
  const [searchText, setSearchText] = useState('');
  const {
    state: { language },
    setLanguage
  } = useContext(LanguageContext);
  const { fetchCategories, fetchAllProducts, searchProducts, state: { categories, allProducts } } = useContext(CategoryContext)

  const props = {
    language
  };

  useEffect(() => {
    async function fetchData() {
      await fetchCategories();
      await fetchAllProducts();
    }

    fetchData();
  }, []);

  const classes = useStyles(props);
  const navigate = useNavigate();

  return (
    <>
      <Navbar
        style={{ paddingBlock: '1%', zIndex: 2 }}
        fixed="top"
        className={isMobileOnly ? classes.navmobile : classes.nav}
        expand="lg"
        collapseOnSelect={true}
      >
        <Container className={classes.container}>
          <Navbar.Brand onClick={() => navigate('/')}>
            <img
              src={require('../images/logo.png')}
              alt="logo"
              style={{
                objectFit: 'contain',
                height: isMobileOnly ? windowHeight / 20 : windowHeight / 16,
                cursor: 'pointer'
              }}
            />
          </Navbar.Brand>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <Search />
            </div>
            <InputBase
              placeholder={language === 'en' ? 'Search…' : 'البحث...'}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ 'aria-label': 'search', style: { direction: language === 'en' ? 'ltr' : 'rtl' } }}
              style={{ border: `2px solid ${appColor}`, borderRadius: 30 }}
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              onKeyPress={async ev => {
                if (ev.key === 'Enter' && searchText) {
                  await searchProducts(searchText);
                  navigate('/search', { state: { searchText } });
                  ev.preventDefault();
                }
              }}
            />
          </div>
          <Navbar.Toggle className={classes.nav} aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              navbarScroll
              className={`navbar-nav ms-auto ${classes.navLinks}`}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: isMobileOnly ? 'column' : 'row',
                  direction: language === 'en' ? 'ltr' : 'rtl'
                }}
              >
                <Nav.Link
                  className={classes.home}
                  onClick={() => navigate('/')}
                >
                  {language === 'en' ? 'Home' : 'الرئيسة'}
                </Nav.Link>
                <Nav.Link
                  className={classes.home}
                  onClick={() => navigate('/about')}
                >
                  {language === 'en' ? 'About' : 'نبذة عنا'}
                </Nav.Link>
                <Nav.Link
                  className={classes.home}
                  onClick={() => navigate('/vision')}
                >
                  {language === 'en' ? 'Our Vision' : 'رؤيتنا'}
                </Nav.Link>
                <Hidden only="xs">
                  <Nav.Link
                    className={classes.home}
                    onClick={() => navigate('/tests')}
                  >
                    {language === 'en' ? 'Tests' : 'الفحوصات'}
                  </Nav.Link>
                </Hidden>
                <Hidden smUp>
                  <NavDropdownMenu
                    title={language === 'en' ? "Tests" : 'الفحوصات'}
                    id="collasible-nav-dropdown"
                  >
                    {categories.map(category => {
                      const products = allProducts.filter(product => product.categoryId === category.id);
                      return (
                        <DropdownSubmenu
                          href="#action/3.7"
                          title={language === 'en' ? category.englishName : category.arabicName}
                        >
                          {products.map(product => {
                            return <NavDropdown.Item onClick={() => {
                              navigate('/product', {
                                state: {
                                  arabicName: product.arabicName,
                                  englishName: product.englishName,
                                  arabicDescription: product.arabicDescription,
                                  englishDescription: product.englishDescription,
                                  imageUrl: AWS_URL + product.imageUrl
                                }
                              })
                            }}
                            >
                              {language === 'en' ? product.englishName : product.arabicName}
                            </NavDropdown.Item>
                          })}
                        </DropdownSubmenu>
                      )
                    })}
                  </NavDropdownMenu>
                </Hidden>
                <Nav.Link
                  className={classes.home}
                  onClick={() => navigate('/locations')}
                >
                  {language === 'en' ? 'Locations' : 'فروعنا'}
                </Nav.Link>
                <Nav.Link className={classes.home} onClick={() => navigate('/contact')}>
                  {language === 'en' ? 'Contact us' : 'تواصل معنا'}
                </Nav.Link>
                <Nav.Link
                  className={classes.home}
                  onClick={() => setLanguage(language === 'en' ? 'ar' : 'en')}
                  data-bs-toggle="collapse"
                  data-bs-target=".navbar-collapse.show"
                >
                  {language === 'en' ? 'العربية' : 'English'}
                </Nav.Link>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className={classes.toolbar}></div>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  home: {
    color: 'green'
    //backgroundColor: 'red'
  },
  nav: {
    backgroundColor: 'white',
    //height: height / 8,
    boxShadow: '0 2px 4px 0 rgba(0,0,0,.2)'
  },
  navMobile: {
    backgroundColor: 'white',
    top: 0,
    height: windowHeight / 6,
    boxShadow: '0 2px 4px 0 rgba(0,0,0,.2)'
  },
  container: {
    backgroundColor: 'white'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: isMobileOnly ? windowWidth / 2.8 : '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(0),
      width: 'auto'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: appColor
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    textAlign: ({ language }) => (language === 'en' ? 'left' : 'right'),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch'
      }
    }
  }
}));

export default NavBar;
