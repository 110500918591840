import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Button,
  Container,
  TextField,
  Fade,
  Box,
  IconButton
} from '@material-ui/core';
import { Delete } from '@material-ui/icons'
import { isMobile } from 'react-device-detect';
import CustomModal from './components/CustomModal';
import { Context as AuthContext } from '../context/AuthContext';
import { Context as PopupContext } from '../context/PopupContext';
import { windowHeight } from '../common';

const AWS_URL = 'https://smartlabs-bucket.s3.eu-central-1.amazonaws.com/';

const PopUp = () => {
  const [modalText, setModalText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [popupImage, setPopupImage] = useState([]);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const navigate = useNavigate();

  const {
    state: { token },
    tryLocalSignin
  } = useContext(AuthContext);

  const { state: { popup }, fetchPopup, uploadPopup, deletePopup } = useContext(PopupContext);

  const handleSubmit = async () => {
    setLoading(true);
    const res = await uploadPopup(popupImage[0]);

    if (res.error) {
      setModalText('There is a problem in saving the popup');
      setShowConfirmButton(false);
    } else {
      setModalText('Popup sent successfully');
      setShowConfirmButton(false);
      setPopupImage([]);
      navigate(0);
    }
    setLoading(false);
  }

  const validateImagesSizes = async event => {
    event.preventDefault();

    Object.values(event.target.files).forEach(imgFile => {
      if (imgFile.size > 1048576) {
        setModalText('There are images that exceed 1MB limit size');
        setModalVisible(true);
        setShowConfirmButton(false);
        //setResetPhotos(true);
        return;
      }
    });

    setPopupImage(event.target.files);
  };

  useEffect(() => {
    if (!token) {
      tryLocalSignin(navigate, '/pop-up');
    }

    fetchPopup();
  }, []);

  if (!token) {
    return null;
  }

  return (
    <Container>
      <Typography className={classes.title}>Pop-Up</Typography>
      <Divider />
      <Grid className={classes.container} container>
        <Grid xs={12}>
          <Typography className={classes.label}>Pop-Up Image</Typography>
          <Button
            className={isMobile ? classes.uploadButton : ''}
            variant="contained"
            component="label"
            color="primary"
            disabled={popup.length}
          >
            Upload Pop-Up Photo
            <input
              name="photos"
              type="file"
              id="files"
              onChange={event => validateImagesSizes(event)}
              hidden
              multiple
            />
          </Button>
          <Typography className={classes.uploadText}>
            {popupImage.length
              ? 'Image uploaded'
              : 'Please Upload Popup Photo'}
          </Typography>
        </Grid>
      </Grid>
      <Typography className={classes.title}>Existing Pop-Up</Typography>
      <Divider />
      {popup.length
        ?
        <>
          <Box className={classes.imageContainer}>
            <img src={AWS_URL + popup[0].imageUrl} className={classes.image} alt="pop-up" />
            <IconButton style={{ marginLeft: '2%' }} aria-label="delete" onClick={() => deletePopup(popup[0].id, navigate)} >
              <Delete color="error" />
            </IconButton>
          </Box>
        </>
        : null}
      <Button
        variant="contained"
        color="secondary"
        className={classes.submitButton}
        disabled={popup.length}
        onClick={() => {
          setModalText('Do you want to Submit this Pop-Up?');
          setShowConfirmButton(true);
          setModalVisible(true);
        }}
      >
        Submit
      </Button>
      <CustomModal
        title="Warning!"
        content={modalText}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
        onConfirm={handleSubmit}
        showConfirmButton={showConfirmButton}
        loading={loading}
      />
    </Container>
  );
};

const useStyles = makeStyles({
  title: {
    fontSize: '1.6em',
    marginBlock: '1%'
  },
  container: {
    marginTop: isMobile ? '5%' : '2%'
  },
  label: {
    fontSize: '1.4em',
    marginBottom: '1%'
  },
  textField: {
    marginBottom: isMobile ? '5%' : '3%',
    width: isMobile ? '100%' : '70%'
  },
  submitButton: {
    float: 'right',
    width: isMobile ? '35%' : '10%',
    //marginTop: '1%',
    marginBottom: '2%'
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  image: {
    objectFit: 'contain',
    height: windowHeight / 3.5
  },
  popUpContent: {
    marginBlock: '3%',
    textAlign: 'center'
  }
});

export default PopUp;