import React, { useContext, useEffect, useRef } from "react";
import { useNavigate, Outlet } from 'react-router-dom';
import {
  Box,
  Typography,
  makeStyles,
  Button,
} from '@material-ui/core';
import { Context as LanguageContext } from '../context/LanguageContext';
import { isMobile } from "react-device-detect";

const AWS_URL = 'https://smartlabs-bucket.s3.eu-central-1.amazonaws.com/';

const Sidebar = ({ children, data, title, setArabicTitle, setEnglishTitle, setArabicDescription, setEnglishDescription, setImageUrl, selectedIndex = 0 }) => {
  const firstButtonRef = useRef(null);
  const {
    state: { language },
  } = useContext(LanguageContext);
  const classes = useStyles();

  useEffect(() => {
    if (firstButtonRef.current) {
      firstButtonRef.current.click();
    }
  }, [data]);

  return (
    <Box className={classes.blogsTitlesContainer}>
      <Typography className={classes.blogsTitlesText}>{title}</Typography>
      <hr />
      {data.map((item, index) => (
        <>
          <Button
            variant="text"
            ref={index === selectedIndex ? firstButtonRef : null}
            onClick={() => {
              setArabicTitle(item.arabicTitle);
              setEnglishTitle(item.englishTitle);
              setArabicDescription(item.arabicDescription);
              setEnglishDescription(item.englishDescription);
              setImageUrl(AWS_URL + item.imageUrl);
            }}
          >
            <Typography>
              {language === 'en' ? item.englishTitle : item.arabicTitle}
            </Typography>
          </Button>
          <hr />
        </>
      ))}
      <div style={{ width: '100%' }}>{children}<Outlet /></div>
    </Box>
  );
};

export const useStyles = makeStyles(theme => ({
  blogsTitlesContainer: {
    boxShadow: theme.shadows[3],
    width: isMobile ? '100%' : '85%',
    padding: '3%',
    borderRadius: 10,
    marginBottom: isMobile ? '5%' : '1%'
  },
  blogsTitlesText: {
    fontSize: '1.4em',
  },
}));

export default Sidebar;