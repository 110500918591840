import createDataContext from './createDataContext';

const LanguageReducer = (state, action) => {
  switch (action.type) {
    case 'set_language':
      return { language: action.payload };
    default:
      return state;
  }
};

const setLanguage = dispatch => {
  return language => {
    window.localStorage.setItem('smart_language', language);
    dispatch({ type: 'set_language', payload: language });
  };
};

export const { Context, Provider } = createDataContext(
  LanguageReducer,
  { setLanguage },
  { language: window.localStorage.getItem('smart_language') || 'en' }
);
