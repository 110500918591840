import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Button,
  Container,
  TextField,
  Fade,
  Select,
  MenuItem,
  CircularProgress
} from '@material-ui/core';
import { isMobileOnly, isTablet } from 'react-device-detect';
import CustomModal from '../components/CustomModal';
import { Context as AuthContext } from '../../context/AuthContext';
import { Context as CategoryContext } from '../../context/CategoryContext';
import { windowWidth } from '../../common';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import JoditEditor from 'jodit-react';

const EditProducts = () => {
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [newEnglishCategoryName, setNewEnglishCategoryName] = useState('');
  const [newArabicCategoryName, setNewArabicCategoryName] = useState('');

  const [selectedProductCategoryId, setSelectedProductCategoryId] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [newEnglishProductName, setNewEnglishProductName] = useState('');
  const [newArabicProductName, setNewArabicProductName] = useState('');
  const [newEnglishDescription, setNewEnglishDescription] = useState('');
  const [newArabicDescription, setNewArabicDescription] = useState('');
  const [newImage, setNewImage] = useState(null);

  const [modalText, setModalText] = useState('');
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [submitType, setSubmitType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resetPhoto, setResetPhoto] = useState(false);

  const editorConfiguration = {
    //plugins: [ Essentials, Bold, Italic, Paragraph ],
    toolbar: ['bold', 'italic', '|', 'link', 'undo', 'redo', '|', 'numberedList', 'bulletedList'],
    link: {
      addTargetToExternalLinks: true
    }

  };

  const navigate = useNavigate();
  const editor = useRef(null);

  const {
    state: { token },
    tryLocalSignin
  } = useContext(AuthContext);

  const {
    fetchCategories,
    fetchProducts,
    editProduct,
    editCategory,
    state: { categories, products }
  } = useContext(CategoryContext);

  useEffect(() => {
    async function fetchCategoriesData() {
      setLoading(true);
      await fetchCategories();
      setLoading(false);
    }

    tryLocalSignin(navigate, '/edit_products');
    fetchCategoriesData();
  }, []);

  const handleSubmit = async () => {
    setLoading(true);

    if (submitType === 'category') {
      const res = await editCategory(
        selectedCategoryId,
        newArabicCategoryName,
        newEnglishCategoryName
      );

      if (res.error) {
        setModalText('There is a problem in editing the category!');
      } else {
        setModalText('Category edited successfully');
        setSelectedCategoryId(null);
        setNewArabicCategoryName('');
        setNewEnglishCategoryName('');

        setLoading(true);
        await fetchCategories();
        setLoading(false);
      }
    } else if (submitType === 'product') {
      const res = await editProduct(
        selectedProductId,
        newArabicProductName,
        newEnglishProductName,
        newArabicDescription,
        newEnglishDescription,
        newImage
      );

      if (res.error) {
        setModalText('There is a problem in editing the product!');
      } else {
        setModalText('Product edited successfully');
        setSelectedProductId(null);
        setNewEnglishProductName('');
        setNewArabicProductName('');
        setNewEnglishDescription('');
        setNewArabicDescription('');
        setNewImage(null);

        setLoading(true);
        await fetchCategories();
        setLoading(false);
      }
    }

    setShowConfirmButton(false);
    setModalVisible(true);
    setLoading(false);
  };

  const validateImagesSizes = async event => {
    event.preventDefault();

    Object.values(event.target.files).forEach(imgFile => {
      if (imgFile.size > 1048576) {
        setModalText('The image exceeds 1MB limit size');
        setModalVisible(true);
        setShowConfirmButton(false);
        setResetPhoto(true);
        return;
      }
    });

    setNewImage(event.target.files[0]);
  };

  const classes = useStyles();

  if (!token) {
    return null;
  }

  return (
    <div>
      <Container style={{ marginTop: '1%' }}>
        {loading && (
          <CircularProgress
            style={{
              display: 'flex',
              position: 'absolute',
              alignSelf: 'center',
              top: '50%',
              left: '50%'
            }}
          />
        )}
        <Fade timeout={1000} in={true}>
          <div>
            <Typography className={classes.title}>Edit Category</Typography>
            <Divider />
            <Grid
              className={
                isMobileOnly ? classes.mobileContainer : classes.container
              }
              container
            >
              <Grid md={4} xs={12}>
                <Typography className={classes.chooseText}>
                  Choose Category
                </Typography>
                <Select
                  placeholder="Choose Category"
                  variant="outlined"
                  className={classes.selectCategory}
                  onChange={async event => {
                    setSelectedCategoryId(event.target.value.id)
                    setNewEnglishCategoryName(event.target.value.englishName)
                    setNewArabicCategoryName(event.target.value.arabicName)
                  }}
                >
                  {categories.map(category => (
                    <MenuItem value={category}>
                      {category.englishName}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid md={4} xs={12}>
                <TextField
                  value={newEnglishCategoryName}
                  onChange={e => setNewEnglishCategoryName(e.target.value)}
                  placeholder="New English Category Name"
                  variant="outlined"
                  className={classes.textField}
                />
              </Grid>
              <Grid md={4} xs={12}>
                <TextField
                  value={newArabicCategoryName}
                  onChange={e => setNewArabicCategoryName(e.target.value)}
                  placeholder="New Arabic Category Name"
                  variant="outlined"
                  className={classes.textField}
                />
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="secondary"
              className={classes.submitButton}
              onClick={() => {
                if (
                  !selectedCategoryId ||
                  !newEnglishCategoryName ||
                  !newArabicCategoryName
                ) {
                  return;
                }

                setSubmitType('category');
                setModalText('Do you want to submit this?');
                setShowConfirmButton(true);
                setModalVisible(true);
              }}
            >
              Submit
            </Button>
            <Typography style={{ marginTop: '5%' }} className={classes.title}>
              Edit Test
            </Typography>
            <Divider />
            <Grid container>
              <Grid md={6} xs={12}>
                <Typography className={classes.chooseText}>
                  Choose Category
                </Typography>
                <Select
                  placeholder="Choose Category"
                  variant="outlined"
                  className={classes.selectCategory}
                  onChange={async event => {
                    setNewArabicProductName('');
                    setNewEnglishProductName('');
                    setNewArabicDescription('');
                    setNewEnglishDescription('');
                    setSelectedProductCategoryId(event.target.value);
                    setSelectedProductId(null);
                    setLoading(true);
                    await fetchProducts(event.target.value);
                    setLoading(false);
                  }}
                >
                  {categories.map(category => (
                    <MenuItem value={category.id}>
                      {category.englishName}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid md={6} xs={12}>
                <Typography className={classes.chooseText}>
                  Choose Product
                </Typography>
                <Select
                  placeholder="Choose Category"
                  variant="outlined"
                  className={classes.selectCategory}
                  onChange={async event => {
                    setSelectedProductId(event.target.value.id);
                    setNewArabicProductName(event.target.value.arabicName);
                    setNewEnglishProductName(event.target.value.englishName);
                    setNewArabicDescription(event.target.value.arabicDescription);
                    setNewEnglishDescription(event.target.value.englishDescription);
                  }}
                >
                  {products.map(product => (
                    <MenuItem value={product}>
                      {product.englishName}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid md={6} xs={12}>
                <TextField
                  value={newEnglishProductName}
                  onChange={e => setNewEnglishProductName(e.target.value)}
                  placeholder="New English Test Name"
                  variant="outlined"
                  className={classes.textField}
                />
              </Grid>
              <Grid md={6} xs={12}>
                <TextField
                  value={newArabicProductName}
                  onChange={e => setNewArabicProductName(e.target.value)}
                  placeholder="New Arabic Test Name"
                  variant="outlined"
                  className={classes.textField}
                />
              </Grid>
              <Grid item md={12}>
                <Typography style={{ marginTop: '3%' }} className={classes.title}>
                  New English Description
                </Typography>
                {/* <TextField
                                    value={newEnglishDescription}
                                    onChange={e => setNewEnglishDescription(e.target.value)}
                                    className={classes.boxField}
                                    id="outlined-basic"
                                    placeholder="Type the new english description here"
                                    variant="outlined"
                                    color="primary"
                                    //fullWidth
                                    multiline
                                    rows={6}
                                /> */}
                <JoditEditor
                  value={newEnglishDescription}
                  onChange={e => setNewEnglishDescription(e)}
                  ref={editor}
                />
              </Grid>
              <Grid item md={12}>
                <Typography style={{ marginTop: '3%' }} className={classes.title}>
                  New Arabic Description
                </Typography>
                {/* <TextField
                                    value={newArabicDescription}
                                    onChange={e => setNewArabicDescription(e.target.value)}
                                    className={classes.boxField}
                                    id="outlined-basic"
                                    placeholder="Type the new arabic description here"
                                    variant="outlined"
                                    color="primary"
                                    //fullWidth
                                    multiline
                                    rows={6}
                                /> */}
                <JoditEditor
                  value={newArabicDescription}
                  onChange={e => setNewArabicDescription(e)}
                  ref={editor}
                />
              </Grid>
              <Grid direction="row" md={4} xs={12}>
                <Button
                  className={classes.uploadButton}
                  variant="contained"
                  component="label"
                  color="primary"
                >
                  Upload New Photo
                  <input
                    name="photos"
                    type="file"
                    id="files"
                    onChange={event => validateImagesSizes(event)}
                    hidden
                  />
                </Button>
                <Typography variant="h6">
                  {newImage
                    ? 'New Photo Selected'
                    : 'Please Select New Photo'}
                </Typography>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="secondary"
              className={classes.submitButton}
              onClick={() => {
                if (
                  !selectedProductCategoryId ||
                  !selectedProductId ||
                  !newArabicProductName ||
                  !newEnglishProductName ||
                  !newArabicDescription ||
                  !newEnglishDescription
                ) {
                  return;
                }

                setSubmitType('product');
                setModalText('Do you want to submit this?');
                setShowConfirmButton(true);
                setModalVisible(true);
              }}
            >
              Submit
            </Button>
            <CustomModal
              title="Warning!"
              content={modalText}
              visible={modalVisible}
              onCancel={() => {
                setModalVisible(false);
                if (resetPhoto) setNewImage(null);
              }}
              onConfirm={handleSubmit}
              showConfirmButton={showConfirmButton}
              loading={loading}
            />
          </div>
        </Fade>
      </Container>
    </div>
  );
};

const useStyles = makeStyles({
  title: {
    fontSize: '1.6em'
  },
  container: {
    marginTop: '2%'
  },
  mobileContainer: {
    marginTop: '5%'
  },
  chooseText: {
    fontSize: '1.3em',
    marginBottom: '1%'
  },
  uploadButton: {
    marginTop: '5%'
  },
  submitButton: {
    float: 'right',
    width: isMobileOnly ? '35%' : '10%',
    marginTop: isMobileOnly ? '5%' : '1%',
    marginBottom: '2%'
  },
  selectCategory: {
    width: isMobileOnly ? '85%' : '50%',
  },
  textField: {
    marginTop: isMobileOnly || isTablet ? '5%' : '10%',
    width: isMobileOnly ? '85%' : '70%'
  },
  boxField: {
    marginTop: '2%',
    marginBottom: '2%',
    width: windowWidth / 1.5
  },
});

export default EditProducts;
