import axios from 'axios';

export const uploadNewImages = async (images, path) => {
  /*Images Configs*/
  let imgsConfigs = [];
  try {
    for (let imgIndx = 0; imgIndx < images.length; imgIndx++) {
      imgsConfigs.push(
        await axios.get('/api/image/config', {
          params: { path }
        })
      );
    }
  } catch (err) {
    return { error: 'Error in uploading image config', imgsConfigs };
  }

  /*Upload Images to AWS*/
  try {
    for (let Index = 0; Index < imgsConfigs.length; Index++) {
      await axios.put(imgsConfigs[Index].data.url, images[Index], {
        headers: {
          'Content-Type': images[Index].type
        }
      });
    }
    return { error: '', imgsConfigs };
  } catch (err) {
    return { error: 'Error in Uploading the image to AWS', imgsConfigs };
  }
};

export const deleteImages = async imagesUrls => {
  try {
    await axios.delete('/api/image/delete', {
      data: { imagesUrls }
    });
    return { error: '' };
  } catch (err) {
    return { error: 'Error in deleting image from AWS' };
  }
};
