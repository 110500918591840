import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Divider
} from '@material-ui/core';
import { useNavigate, useLocation } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';
import NavBar from '../components/Navbar';
import { Context as LanguageContext } from '../context/LanguageContext';
import { Context as BlogsContext } from '../context/BlogsContext';
import { appColor, windowHeight, windowWidth } from '../common';
import { isMobile, isMobileOnly } from 'react-device-detect';
import Sidebar from '../components/Sidebar';

const AWS_URL = 'https://smartlabs-bucket.s3.eu-central-1.amazonaws.com/';

const BlogsScreen = () => {
  const [arabicTitle, setArabicTitle] = useState('');
  const [englishTitle, setEnglishTitle] = useState('');
  const [arabicDescription, setArabicDescription] = useState('');
  const [englishDescription, setEnglishDescription] = useState('');
  const [imageUrl, setImageUrl] = useState(null);

  const location = useLocation();
  let selectedIndex = 0;
  if (location.state) {
    selectedIndex = location.state.selectedIndex;
  }

  const {
    state: { language },
  } = useContext(LanguageContext);
  const { fetchBlogs, state: { blogs } } = useContext(BlogsContext);

  const props = {
    language
  };

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      await fetchBlogs();
    }

    Aos.init({
      duration: 2000
    });

    fetchData();
  }, []);

  const classes = useStyles(props);
  return (
    <Box>
      <NavBar />
      <Container style={{ marginTop: '3%' }}>
        <Box className={classes.titleContainer}>
          <img
            src={require('../images/book.png')}
            alt='book'
            className={classes.bookImg}
          />
          <Box>
            <Typography className={classes.title}>
              {language === 'en' ? 'Blogs' : 'المقالات'}
            </Typography>
            <Divider className={classes.divider} />
          </Box>
        </Box>
        <div style={{ direction: language === 'en' ? 'ltr' : 'rtl' }} data-aos="fade-right" className={classes.root}>
          <Grid container>
            <Grid item md={4} xs={12}>
              <Sidebar
                title={language === 'en' ? 'Blogs Titles' : 'عناوين المقالات'}
                data={blogs}
                setArabicTitle={setArabicTitle}
                setEnglishTitle={setEnglishTitle}
                setArabicDescription={setArabicDescription}
                setEnglishDescription={setEnglishDescription}
                setImageUrl={setImageUrl}
                selectedIndex={selectedIndex}
              />
            </Grid>
            <Grid item md={8} xs={12}>
              <Box style={{ direction: language === 'en' ? 'ltr' : 'rtl' }}>
                {imageUrl && <img
                  src={imageUrl}
                  alt="blog_photo"
                  className={classes.img}
                />}
                <Typography style={{ textAlign: language === 'en' ? 'left' : 'right' }} className={classes.title}>
                  {language === 'en' ? englishTitle : arabicTitle}
                </Typography>
                <Divider style={{ marginBottom: '1%' }} />
                <Typography style={{ textAlign: language === 'en' ? 'left' : 'right' }} className={classes.content}>
                  {language === 'en' ? <div dangerouslySetInnerHTML={{ __html: englishDescription }} /> : <div dangerouslySetInnerHTML={{ __html: arabicDescription }} />}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Box>
  );
};

export const useStyles = makeStyles(theme => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '3%'
  },
  bookImg: {
    objectFit: 'contain',
    height: windowHeight / 20,
    marginInline: '2%'
  },
  blogsTitlesContainer: {
    boxShadow: theme.shadows[3],
    width: isMobile ? '100%' : '85%',
    padding: '3%',
    borderRadius: 10,
    marginBottom: isMobile ? '5%' : '1%'
  },
  blogsTitlesText: {
    fontSize: '1.4em',
  },
  title: {
    fontSize: '1.7em',
    color: appColor
  },
  divider: {
    marginTop: '1%',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%'
  },
  standImageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardContainer: {
    marginInline: '2%',
    marginBottom: '5%'
  },
  media: {
    height: isMobileOnly ? windowHeight / 3 : windowHeight / 2.4,
    objectFit: 'cover',
    width: isMobileOnly ? windowWidth : windowWidth / 2.5
  },
  learnText: {
    background: 'linear-gradient(to right, #1b2551, #acafaf)',
    color: 'white',
    '&:hover': {
      backgroundColor: appColor,
      color: 'white'
    }
  },
  cardContent: {
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
    //textAlign: ({ language }) => (language === 'en' ? 'left' : 'right')
  },
  img: {
    objectFit: 'contain',
    width: isMobileOnly ? '100%' : windowWidth / 5.5,
    borderRadius: 10,
    float: 'left',
    marginRight: '1%',
    marginBottom: '1%'
  },
  content: {
    display: 'inline',
    marginBlock: '3%',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word'
  },
}))

export default BlogsScreen;