import React from "react";
import { windowWidth } from "../common";

const PrivacyArabicScreen = () => {
  return (
    <section id="privacy">
      <div className="container" style={{ direction: 'rtl' }}>
        <nav
          className="navbar navbar-expand-lg navbar-light" //rgba(0,0,0,0.8)
        >
          <div className="container">
            <a className="navbar-brand" href="/privacy">
              <img
                src={require('../images/logo.png')}
                alt="logo"
                className="logo"
                style={{
                  objectFit: 'contain',
                  height: windowWidth / 30,
                }}
              />
            </a>
          </div>
        </nav>
        <hr style={{ marginBottom: '1%', marginTop: 0 }} />
        <h2>سياسة الخصوصية</h2>
        <p>
          تُستخدم هذه الصفحة لإعلام الزائرين بسياساتنا بجمع المعلومات الشخصية واستخدامها والكشف عنها إذا قرر أي شخص استخدام خدمتنا.
        </p>
        <p>
          إذا اخترت استخدام خدمتنا ، فأنت توافق على جمع واستخدام المعلومات المتعلقة بهذه السياسة. تُستخدم المعلومات الشخصية التي نجمعها لتوفير الخدمة وتحسينها. لن نستخدم أو نشارك معلوماتك مع أي شخص باستثناء ما هو موضح في سياسة الخصوصية هذه.
        </p>
        <p>
          <h4>جمع المعلومات واستخدامها</h4>
        </p>
        <p>
          للحصول على تجربة أفضل أثناء استخدام خدمتنا ، قد نطلب منك تزويدنا بمعلومات تعريف شخصية معينة ، بما في ذلك على سبيل المثال لا الحصر اسم المستخدم ورقم الهاتف. يتم الاحتفاظ بالمعلومات التي نطلبها على جهازك ولا نقوم بجمعها بأي شكل من الأشكال.
        </p>

        <p>
          <strong>مقدمو الخدمة</strong> يجوز لنا توظيف شركات وأفراد تابعين لجهات خارجية للأسباب التالية:
        </p>
        <ul>
          <li>لتسهيل خدمتنا.</li>
          <li>لمساعدتنا في تحليل كيفية استخدام خدمتنا.</li>
        </ul>
        <p>
          نريد إبلاغ مستخدمي هذه الخدمة أن هذه الأطراف الثالثة لديها حق الوصول إلى معلوماتك الشخصية. والسبب هو أداء المهام الموكلة إليهم نيابة عنا. ومع ذلك ، فهم ملزمون بعدم الكشف عن المعلومات أو استخدامها لأي غرض آخر.
        </p>
        <p>
          <h4>خصوصية الأطفال</h4>
        </p>
        <p>
          لا تتعامل هذه الخدمات مع أي شخص يقل عمره عن 13 عامًا. نحن لا نجمع عن قصد معلومات تعريف شخصية من الأطفال دون سن 13 عامًا. إذا كنت أحد الوالدين أو الوصي وتدرك أن طفلك قد زودنا بمعلومات شخصية ، فيرجى الاتصال بنا. أننا سنكون قادرين على القيام بالإجراءات اللازمة.
        </p>
        <p>
          <h4>التغييرات على سياسة الخصوصية</h4>
        </p>
        <p>
          قد نقوم بتحديث سياسة الخصوصية الخاصة بنا من وقت لآخر. وبالتالي ، يُنصح بمراجعة هذه الصفحة بشكل دوري لمعرفة أي تغييرات. سنخطرك بأي تغييرات عن طريق نشر سياسة الخصوصية الجديدة على هذه الصفحة. تسري هذه التغييرات فور نشرها على هذه الصفحة.
        </p>
        <p>
          <h4>اتصل بنا</h4>
        </p>
        <p>
          إذا كان لديك أي أسئلة أو اقتراحات حول سياسة الخصوصية الخاصة بنا ، فلا تتردد في الاتصال بنا عبر البريد الإلكتروني: info@smartlabsgroup.com
        </p>
      </div>
    </section>
  );
};

export default PrivacyArabicScreen;