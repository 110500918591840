import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    makeStyles,
    Grid,
    Typography,
    Divider,
    Button,
    Container,
    Fade,
    MenuItem,
    Select,
    CircularProgress
} from '@material-ui/core';
import { isMobileOnly } from 'react-device-detect';
import CustomModal from '../components/CustomModal';
import { Context as AuthContext } from '../../context/AuthContext';
import { Context as NewsContext } from '../../context/NewsContext';


const DeleteNews = () => {
    const [selectedNewsId, setSelectedNewsId] = useState(null);

    const [modalText, setModalText] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [showConfirmButton, setShowConfirmButton] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const {
        state: { token },
        tryLocalSignin
    } = useContext(AuthContext);
    const {
        fetchNews,
        deleteNews,
        state: { news }
    } = useContext(NewsContext);


    useEffect(() => {
        async function fetchNewsData() {
            setLoading(true);
            await fetchNews();
            setLoading(false);
        }

        tryLocalSignin(navigate, '/delete_news');
        fetchNewsData();
    }, []);


    const handleSubmit = async () => {
        setLoading(true);

        const res = await deleteNews(selectedNewsId);

        if (res.error) {
            setModalText('There is a problem in deleting the News!');
        } else {
            setModalText('News deleted successfully');
            setSelectedNewsId(null);
            fetchNews();
        }

        setShowConfirmButton(false);
        setModalVisible(true);
        setLoading(false);
    };

    const classes = useStyles();

    if (!token) {
    return null;
    }

    return (
        <div>
            <Container style={{ marginTop: '1%' }}>
                {loading && (
                    <CircularProgress
                        style={{
                            display: 'flex',
                            position: 'absolute',
                            alignSelf: 'center',
                            top: '50%',
                            left: '50%'
                        }}
                    />
                )}
                <Fade timeout={1000} in={true}>
                    <div>
                        <Typography className={classes.title}>Delete News</Typography>
                        <Divider />
                        <Grid
                            className={
                                isMobileOnly ? classes.mobileContainer : classes.container
                            }
                            container
                        >
                            <Grid xs={12}>
                                <Typography className={classes.chooseText}>
                                    Choose New
                                </Typography>
                                <Select
                                    placeholder="Choose new"
                                    variant="outlined"
                                    className={classes.selectNews}
                                    onChange={async event => {
                                        setSelectedNewsId(event.target.value);
                                    }}
                                >
                                    {news.map(newsObj => {
                                        return (
                                            <MenuItem value={newsObj.id}>
                                                {newsObj.englishTitle}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </Grid>
                        </Grid>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.submitButton}
                            onClick={() => {
                                if (!selectedNewsId) {
                                    return;
                                }

                                setModalText(
                                    'Do you want to submit this?'
                                );
                                setShowConfirmButton(true);
                                setModalVisible(true);
                            }}
                        >
                            Submit
                        </Button>
                        <CustomModal
                            title="Warning!"
                            content={modalText}
                            visible={modalVisible}
                            onCancel={() => setModalVisible(false)}
                            onConfirm={handleSubmit}
                            showConfirmButton={showConfirmButton}
                            loading={loading}
                        />
                    </div>
                </Fade>
            </Container>
        </div>
    );
};

const useStyles = makeStyles({
    title: {
        fontSize: '1.6em'
    },
    container: {
        marginTop: '2%'
    },
    mobileContainer: {
        marginTop: '5%'
    },
    chooseText: {
        fontSize: '1.3em',
        marginBottom: '1%'
    },
    selectNews: {
        width: isMobileOnly ? '85%' : '50%'
        //marginBottom: isMobileOnly ? '5%' : 0
    },
    uploadButton: {
        marginTop: '5%'
    },
    submitButton: {
        float: 'right',
        width: isMobileOnly ? '35%' : '10%',
        marginTop: isMobileOnly ? '5%' : 0,
        marginBottom: '2%'
    }
});

export default DeleteNews;
