import React, { useContext, useEffect } from 'react';
import {
  Container,
  Typography,
  Divider,
  Box,
  makeStyles,
} from '@material-ui/core';
import Aos from 'aos';
import 'aos/dist/aos.css';
import NavBar from "../components/Navbar";
import { Context as LanguageContext } from '../context/LanguageContext';
import { appColor } from '../common';

const VisionScreen = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({
      duration: 2000
    });
  })

  const {
    state: { language },
  } = useContext(LanguageContext);
  return (
    <Box>
      <NavBar />
      <Container data-aos={language === 'en' ? "fade-right" : 'fade-left'} style={{ direction: language === 'en' ? 'ltr' : 'rtl', marginTop: '5%' }}>
        <Typography className={classes.title}>
          {language === 'en' ? 'Our Vision' : 'رؤيتنا'}
        </Typography>
        <Divider />
        <Typography className={classes.content}>
          {language === 'en' ?
            `Rendering Smart Labs a reference for all special tests in MENA region and worldwide to empower health and impact lives`
            : `أن نكون مرجعًا لجميع الفحوصات الخاصة في منطقة الشرق الأوسط وشمال إفريقيا وجميع أنحاء العالم لتمكين الصحة والطب المخبري`
          }
        </Typography>
        <Typography style={{ marginTop: '2%' }} className={classes.title}>
          {language === 'en' ? 'Our Mission' : 'مهمتنا'}
        </Typography>
        <Divider />
        <Typography className={classes.content}>
          {language === 'en' ?
            `Ongoing provision of finest medical care with the highest standards to assist our patients, additionally to reaching the international level in the ﬁeld of laboratory medicine and researches`
            : `تقديم أفضل رعاية طبية بأعلى المعايير لمساعدة مرضانا ، بالإضافة إلى الوصول إلى المستوى الدولي والعالمي في مجال الطب المخبري والبحوث العلمية.`
          }
        </Typography>
        <Typography style={{ marginTop: '2%' }} className={classes.title}>
          {language === 'en' ? 'Message from the president' : 'رسالة من الرئيس'}
        </Typography>
        <Divider />
        <Typography className={classes.content}>
          {language === 'en' ?
            `Medical laboratories are considered an essential aspect of the medical sector, they are essential for deciding the right treatment that contributes directly to the health of population. Laboratories are also responsible for the introduction of new types and methods of analysis, including advances in accuracy and speed in results.

          Smart Lab Group has prioritized clear and accuracy-driven approaches, this includes adopting the latest technologies, leveraging the expertise of professional employees with Master’s and PhD’s, and constantly improving our services.

          Since our establishment, we have sought to improve the overall health and well-being of the communities we serve by providing top quality laboratory diagnostic services. Smart Labs seeks to achieve optimal performance in all endeavors, in order to maintain the organizational, medical and economic excellence of the group.
          `
            : `تعتبر المختبرات الطبية جانبًا أساسيًا من جوانب القطاع الطبي ، فهي ضرورية لتحديد العلاج المناسب الذي يساهم بشكل مباشر في التأثير على صحة المواطنين. المختبرات مسؤولة أيضًا عن إدخال أنواع وطرق جديدة للتحاليل، بما في ذلك التقدم في الدقة والسرعة في النتائج.

          أعطت مجموعة مختبرات سمارت لاب الأولوية للنُهج الواضحة  والتفكير المستقبلي  القائم على الدقة ، ويشمل ذلك اعتماد أحدث التقنيات والاستفادة من خبرة الموظفين المحترفين الحاصلين على شهادات الماجستير والدكتوراه ، وتحسين خدماتنا باستمرار.
          
          منذ تأسيسنا ، سعينا إلى تحسين الصحة العامة ورفاهية المجتمعات التي نخدمها من خلال توفير خدمات التشخيص المخبرية عالية الجودة. تسعى مختبراتنا إلى تحقيق الأداء الأمثل في جميع النواحي وذلك من أجل الحفاظ على التميز التنظيمي والطبي والاقتصادي للمجموعة.
          `
          }
        </Typography>
      </Container>
    </Box>
  );
};

export const useStyles = makeStyles({
  title: {
    fontSize: '1.5em',
    color: appColor
  },
  content: {
    fontSize: '1.2em',
    marginTop: '1%',
    marginBottom: '5%',
    whiteSpace: 'pre-line',
    overflow: 'hidden'
  }
})


export default VisionScreen;