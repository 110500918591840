import axios from 'axios';
import createDataContext from './createDataContext';
import { deleteImages, uploadNewImages } from './commons';

const categoryReducer = (state, action) => {
  switch (action.type) {
    case 'upload_category':
      return { ...state, errorMessage: '' };
    case 'upload_product':
      return { ...state, errorMessage: '' };
    case 'fetch_categories':
      return { ...state, errorMessage: '', categories: action.payload };
    case 'fetch_products':
      return { ...state, errorMessage: '', products: action.payload };
    case 'fetch_all_products':
      return { ...state, errorMessage: '', allProducts: action.payload };
    case 'edit_category':
      return {
        ...state,
        errorMessage: '',
        categories: state.categories.map(category => {
          if (category.id === action.payload.id) {
            return { ...category, ...action.payload };
          } else {
            return category;
          }
        })
      };
    case 'edit_product':
      return {
        ...state,
        errorMessage: '',
        products: state.products.map(product => {
          if (product.id === action.payload.id) {
            return { ...product, ...action.payload };
          } else {
            return product;
          }
        })
      };
    case '':
      return { ...state, errorMessage: '' };
    case 'delete_product':
      return { ...state, errorMessage: '' };
    case 'delete_category':
      return { ...state, errorMessage: '' };
    case 'search_products':
      return { ...state, errorMessage: '', searchRes: action.payload }
    case 'reset_products':
      return { ...state, errorMessage: '', products: [] };
    case 'error':
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};

const editImages = async (
  images,
  selectedProductId,
  imageKey,
  singleImage
) => {
  const uploadImageRes = await uploadNewImages(
    images,
    `products`
  );
  if (uploadImageRes.error) {
    return { error: uploadImageRes.error };
  }

  let toBeEditedProduct;
  try {
    const fetchingRes = await axios.get('/api/product', { params: { productId: selectedProductId } });
    toBeEditedProduct = fetchingRes.data[0];
  } catch(err) {
    console.log(err);
    return { error: 'Error in Fetching the product to be edited' };
  }

  try {
    let imagesPaths;
    if (singleImage) {
      imagesPaths = uploadImageRes.imgsConfigs.map(
        config => config.data.key
      )[0];
    } else {
      imagesPaths = uploadImageRes.imgsConfigs.map(config => config.data.key);
    }

    await axios.put('/api/product', {
      id: selectedProductId,
      [imageKey]: imagesPaths
    });

    const deleteRes = await deleteImages([
      toBeEditedProduct.imageUrl
    ]);

    if (deleteRes.error) {
      return { error: deleteRes.error };
    } else {
      return { error: '' };
    }
  } catch (err) {
    console.log(err);
    return { error: 'Error in Editing the item' };
  }
};

const uploadCategory =
  dispatch => async (arabicName, englishName) => {
    try {
      await axios.post('/api/category', {
        arabicName,
        englishName
      });
      dispatch({ type: 'upload_category' });
      return { error: '' };
    } catch {
      dispatch({
        type: 'error',
        payload: 'Something went wrong while uploading category to the database'
      });
      return { error: 'Error in Uploading Category to the database' };
    }
  };

const uploadProduct =
  dispatch => async (arabicName, englishName, arabicDescription, englishDescription, image, categoryId) => {
    /*Image Config*/
    let imgConfig;
    try {
      imgConfig = await axios.get('/api/image/config', {
        params: {
          path: `products`
        }
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: 'error',
        payload: 'Something went wrong while uploading product image config'
      });
      return { error: 'Error in uploading product image config' };
    }

    /*Upload Image to AWS*/
    try {
      await axios.put(imgConfig.data.url, image, {
        headers: {
          'Content-Type': image.type
        }
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: 'error',
        payload: 'Something went wrong while uploading product image to AWS'
      });
      return { error: 'Error in Uploading product image to AWS' };
    }

    try {
      await axios.post('/api/product', {
        arabicName,
        englishName,
        arabicDescription,
        englishDescription,
        imageUrl: imgConfig.data.key,
        categoryId
      });
      dispatch({ type: 'upload_product' });
      return { error: '' };
    } catch {
      dispatch({
        type: 'error',
        payload:
          'Something went wrong while uploading product to the database'
      });
      return { error: 'Error in Uploading product to the database' };
    }
  };

const fetchCategories = dispatch => async () => {
  try {
    const res = await axios.get('/api/category');
    dispatch({ type: 'fetch_categories', payload: res.data });
    return { error: '', categories: res.data };
  } catch (err) {
    dispatch({
      type: 'error',
      payload: 'Something went wrong while fetching categories'
    });
    return { error: 'Error in Fetching categories', categories: [] };
  }
};

const fetchProducts = dispatch => async (categoryId, updateState=true) => {
  try {
    const res = await axios.get('/api/products', {
      params: { categoryId }
    });
    if (updateState) {
      dispatch({ type: 'fetch_products', payload: res.data });
    }
    return { error: '', products: res.data };
  } catch (err) {
    dispatch({
      type: 'error',
      payload: 'Something went wrong while fetching products'
    });
    return { error: 'Error in Fetching products', products: [] };
  }
};

const fetchAllProducts = dispatch => async () => {
  try {
    const res = await axios.get('/api/products', {
      params: { categoryId: null }
    });
    dispatch({ type: 'fetch_all_products', payload: res.data });
    return { error: '', allProducts: res.data };
  } catch (err) {
    dispatch({
      type: 'error',
      payload: 'Something went wrong while fetching products'
    });
    return { error: 'Error in Fetching products', products: [] };
  }
};

const editCategory =
  dispatch =>
  async (
    selectedCategoryId,
    newArabicName,
    newEnglishName
  ) => {
    try {
      await axios.put('/api/category', {
        id: selectedCategoryId,
        arabicName: newArabicName,
        englishName: newEnglishName
      });
      dispatch({
        type: 'edit_category',
        payload: {
          id: selectedCategoryId,
          arabicName: newArabicName,
          englishName: newEnglishName
        }
      });

      return { error: '' };
    } catch (err) {
      dispatch({
        type: 'error',
        payload: 'Something went wrong while editing the category'
      });
      return { error: 'Error in Editing the category' };
    }
  };

const editProduct =
  dispatch =>
  async (
    selectedProductId,
    newArabicName,
    newEnglishName,
    newArabicDescription,
    newEnglishDescription,
    newImage
  ) => {
    if (newImage) {
      const res = editImages(
        [newImage],
        selectedProductId,
        'imageUrl',
        true
      );
      if (res.error) {
        dispatch({
          type: 'error',
          payload: res.error
        });
        return { error: res.error };
      }
    }

    try {
      await axios.put('/api/product', {
        id: selectedProductId,
        arabicName: newArabicName,
        englishName: newEnglishName,
        arabicDescription: newArabicDescription,
        englishDescription: newEnglishDescription
      });
      dispatch({
        type: 'edit_product',
        payload: {
          id: selectedProductId,
          arabicName: newArabicName,
          englishName: newEnglishName,
          arabicDescription: newArabicDescription,
          englishDescription: newEnglishDescription
        }
      });

      return { error: '' };
    } catch (err) {
      dispatch({
        type: 'error',
        payload: 'Something went wrong while editing the product'
      });
      return { error: 'Error in Editing the product' };
    }
  };

const searchProducts = dispatch => async searchText => {
  try {
    const res = await axios.get('/api/search', {
      params: { searchText }
    });

    dispatch({ type: 'search_products', payload: res.data });
    return { error: '', searchRes: res.data };
  } catch (err) {
    console.log(err);
    dispatch({
      type: 'error',
      payload:
        'Something went wrong while search the products'
    });
    return { error: 'Error in searching the products' };
  }
};

const deleteProduct = dispatch => async productId => {
  try {
    const fetchingRes = await axios.get('/api/product', {
      params: { productId }
    });
    const imageUrl = fetchingRes.data[0].imageUrl;

    await axios.delete('/api/product', {
      data: { id: productId }
    });

    deleteImages([imageUrl]);

    dispatch({ type: 'delete_product' });
    return { error: '' };
  } catch (err) {
    console.log(err);
    dispatch({
      type: 'error',
      payload:
        'Something went wrong while deleting the product from the database'
    });
    return { error: 'Error in deleting the product from the database' };
  }
};

const deleteCategory = dispatch => async categoryId => {
  try {
    const res = await axios.delete('/api/category', {
      data: { id: categoryId }
    });

    dispatch({ type: 'delete_category', payload: res.data });
    return { error: '' };
  } catch (err) {
    dispatch({
      type: 'error',
      payload: 'Something went wrong while deleting the category'
    });
    return { error: 'Error in deleting the category' };
  }
};

const resetProductsState = dispatch => () => {
  dispatch({ type: 'reset_products' });
};

export const { Context, Provider } = createDataContext(
  categoryReducer,
  {
    fetchCategories,
    fetchProducts,
    fetchAllProducts,
    uploadCategory,
    uploadProduct,
    editCategory,
    editProduct,
    deleteCategory,
    deleteProduct,
    searchProducts,
    resetProductsState
  },
  { categories: [], products: [], allProducts:[], searchRes: [], errorMessage: '' }
);
