import axios from 'axios';
import createDataContext from './createDataContext';
import { uploadNewImages, deleteImages } from './commons';

const blogsReducer = (state, action) => {
  switch (action.type) {
    case 'upload_blogs':
      return { ...state, errorMessage: '' };
    case 'fetch_blogs':
      return { ...state, errorMessage: '', blogs: action.payload };
    // case 'edit_news':
    //   return {
    //     ...state,
    //     errorMessage: '',
    //     news: state.news.map(newsItem => {
    //       if (newsItem._id === action.payload._id) {
    //         return { ...newsItem, ...action.payload };
    //       } else {
    //         return newsItem;
    //       }
    //     })
    //   };
    case 'delete_blogs':
      return { ...state, errorMessage: '' };
    case 'reset_blogs':
      return { ...state, errorMessage: '', blogs: [] };
    case 'error':
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};

const editImages = async (
  images,
  selectedBlogsId,
  imageKey,
  singleImage
) => {
  const uploadImageRes = await uploadNewImages(
    images,
    `blogs`
  );
  if (uploadImageRes.error) {
    return { error: uploadImageRes.error };
  }

  let toBeEditedBlogs;
  try {
    const fetchingRes = await axios.get('/api/blogs', { params: { selectedBlogsId } });
    toBeEditedBlogs = fetchingRes.data[0];
  } catch (err) {
    console.log(err);
    return { error: 'Error in Fetching the blogs to be edited' };
  }

  try {
    let imagesPaths;
    if (singleImage) {
      imagesPaths = uploadImageRes.imgsConfigs.map(
        config => config.data.key
      )[0];
    } else {
      imagesPaths = uploadImageRes.imgsConfigs.map(config => config.data.key);
    }

    await axios.put('/api/blogs', {
      selectedBlogsId,
      [imageKey]: imagesPaths
    });

    const deleteRes = await deleteImages([
      toBeEditedBlogs.imageUrl
    ]);

    if (deleteRes.error) {
      return { error: deleteRes.error };
    } else {
      return { error: '' };
    }
  } catch (err) {
    console.log(err);
    return { error: 'Error in Editing the item' };
  }
};

const uploadBlogs =
  dispatch => async (arabicTitle, englishTitle, image, arabicDescription, englishDescription) => {
    /*Image Config*/
    let imgConfig;
    try {
      imgConfig = await axios.get('/api/image/config', {
        params: {
          path: `blogs`
        }
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: 'error',
        payload: 'Something went wrong while uploading blogs image config'
      });
      return { error: 'Error in uploading blogs image config' };
    }

    /*Upload Image to AWS*/
    try {
      await axios.put(imgConfig.data.url, image, {
        headers: {
          'Content-Type': image.type
        }
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: 'error',
        payload: 'Something went wrong while uploading blogs image to AWS'
      });
      return { error: 'Error in Uploading blogs image to AWS' };
    }

    try {
      await axios.post('/api/blogs', {
        arabicTitle,
        englishTitle,
        imageUrl: imgConfig.data.key,
        arabicDescription,
        englishDescription
      });
      dispatch({ type: 'upload_blogs' });
      return { error: '' };
    } catch {
      dispatch({
        type: 'error',
        payload: 'Something went wrong while uploading blogs to the database'
      });
      return { error: 'Error in Uploading blogs to the database' };
    }
  };

const fetchBlogs = dispatch => async () => {
  try {
    const res = await axios.get('/api/blogs');

    dispatch({ type: 'fetch_blogs', payload: res.data });
    return { error: '' };
  } catch (err) {
    dispatch({
      type: 'error',
      payload: 'Something went wrong while fetching blogs'
    });
    return { error: 'Error in Fetching blogs' };
  }
};

const editBlogs = dispatch => async (
  selectedBlogsId,
  newArabicTitle,
  newEnglishTitle,
  newArabicDescription,
  newEnglishDescription,
  newImage
) => {
  if (newImage) {
    const res = editImages(
      [newImage],
      selectedBlogsId,
      'imageUrl',
      true
    );
    if (res.error) {
      dispatch({
        type: 'error',
        payload: res.error
      });
      return { error: res.error };
    }
  }

  try {
    await axios.put('/api/blogs', {
      selectedBlogsId,
      arabicTitle: newArabicTitle,
      englishTitle: newEnglishTitle,
      arabicDescription: newArabicDescription,
      englishDescription: newEnglishDescription
    });
    dispatch({
      type: 'edit_product',
      payload: {
        selectedBlogsId,
        arabicTitle: newArabicTitle,
        englishTitle: newEnglishTitle,
        arabicDescription: newArabicDescription,
        englishDescription: newEnglishDescription
      }
    });

    return { error: '' };
  } catch (err) {
    console.log(err)
    dispatch({
      type: 'error',
      payload: 'Something went wrong while editing the blogs'
    });
    return { error: 'Error in Editing the blogs' };
  }
}

const deleteBlogs = dispatch => async selectedBlogsId => {
  try {
    const fetchingRes = await axios.get('/api/blogs', { params: { selectedBlogsId } });
    const imageUrl = fetchingRes.data[0].imageUrl;

    await axios.delete('/api/blogs', {
      data: { id: selectedBlogsId }
    });

    deleteImages([imageUrl]);

    dispatch({ type: 'delete_blogs' });
    return { error: '' };
  } catch (err) {
    dispatch({
      type: 'error',
      payload: 'Something went wrong while deleting the blogs'
    });
    return { error: 'Error in deleting the blogs' };
  }
};

const resetBlogsState = dispatch => () => {
  dispatch({ type: 'reset_blogs' });
};

export const { Context, Provider } = createDataContext(
  blogsReducer,
  {
    fetchBlogs,
    uploadBlogs,
    editBlogs,
    deleteBlogs,
    resetBlogsState
  },
  { blogs: [], errorMessage: '' }
);
