import React, { useContext } from 'react';
import {
  Dialog,
  Button,
  makeStyles,
  Slide,
  Box
} from '@material-ui/core';
import { Context as LanguageContext } from '../context/LanguageContext';
import { isMobileOnly } from 'react-device-detect';
import { appColor, windowWidth } from '../common';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PopUpModal = ({
  img,
  visible,
  onClose,
}) => {
  const {
    state: { language }
  } = useContext(LanguageContext);

  const props = {
    language
  };


  const classes = useStyles(props);
  return (
    <>
      <Dialog
        open={visible}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{ style: { width: isMobileOnly ? '100%' : '70%', borderRadius: 30, padding: 0, margin: 0 } }}
        onClose={onClose}
      >
        <img src={img} alt="pop-up-img" className={classes.image} />
        <Box onClick={onClose} className={classes.buttonContainer}>
          <Button className={classes.button}>
            {language === 'en' ? 'Close' : 'إغلاق'}
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles({
  image: {
    width: '100%',
    objectFit: 'cover'
  },
  buttonContainer: {
    display: 'flex',
    alignSelf: 'center',
    position: 'absolute',
    zIndex: 2,
    bottom: '2%'
  },
  button: {
    backgroundColor: appColor,
    width: windowWidth / 8,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: appColor,
      color: '#FFFFFF'
    }
  }
});

export default PopUpModal;
