import React, { useState, useEffect, useContext } from 'react';
import {
  makeStyles,
  Typography,
  Container,
  Fade,
  Divider,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Box,
  TextField,
  InputAdornment
} from '@material-ui/core';
import { ExpandMore, Search } from '@material-ui/icons';
import { Context as AuthContext } from '../context/AuthContext';
import { appColor } from '../common';

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [searchValue, setSearchValue] = useState('')
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { fetchCustomers } = useContext(AuthContext);

  const classes = useStyles();

  useEffect(() => {
    async function fetchData() {
      const { error, customers } = await fetchCustomers();
      if (!error) {
        setCustomers(customers)
      } else {
        console.log('Somthing went wrong while fetching customers')
      }
    }
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  return (
    <Container>
      <Fade timeout={1000} in={true}>
        <div>
          <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2%', alignItems: 'center', marginBottom: '1%' }}>
            <Typography className={classes.title}>Customers</Typography>
            <TextField
              value={searchValue}
              onChange={(e) => { setSearchValue(e.target.value); setPage(0); }}
              style={{ width: '40%' }}
              variant='outlined'
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search style={{ color: 'gray' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <hr />
          <TableContainer style={{ marginBottom: '5%' }} component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell className={classes.tableHeaderText}>Name</TableCell>
                  <TableCell className={classes.tableHeaderText}>Phone Number</TableCell>
                  <TableCell className={classes.tableHeaderText}>Birthdate</TableCell>
                  <TableCell className={classes.tableHeaderText}>Gender</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customers
                  .filter(customer => customer.firstName.toLowerCase().includes(searchValue.toLowerCase()))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((customer) => (
                    <TableRow key={customer.phoneNumber}>
                      <TableCell className={classes.content} component="th" scope="row">
                        {customer.firstName} {customer.lastName}
                      </TableCell>
                      <TableCell className={classes.content}>{customer.phoneNumber}</TableCell>
                      <TableCell className={classes.content}>{customer.birthdate.split('T')[0]}</TableCell>
                      <TableCell className={classes.content}>{customer.gender}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5]}
              component="div"
              count={customers
                .filter(customer => customer.firstName.toLowerCase().includes(searchValue.toLowerCase()))
                .length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </div>
      </Fade>
    </Container>
  );
};

const useStyles = makeStyles({
  title: {
    fontSize: '1.6em',
  },
  table: {
    minWidth: 650,
  },
  tableHeader: {
    backgroundColor: appColor,
  },
  tableHeaderText: {
    color: '#FFFFFF',
    fontSize: '1em',
    fontWeight: 'bold'
  },
  content: {
    fontSize: '1em'
  }
});

export default Customers;