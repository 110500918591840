import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Button,
  Container,
  Fade,
  MenuItem,
  Select,
  CircularProgress
} from '@material-ui/core';
import { isMobileOnly } from 'react-device-detect';
import CustomModal from '../components/CustomModal';
import { Context as AuthContext } from '../../context/AuthContext';
import { Context as CategoryContext } from '../../context/CategoryContext';

const DeleteProducts = () => {
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedProductCategoryId, setSelectedProductCategoryId] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);

  const [modalText, setModalText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [submitType, setSubmitType] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const {
    state: { token },
    tryLocalSignin
  } = useContext(AuthContext);
  const {
    fetchCategories,
    fetchProducts,
    deleteProduct,
    deleteCategory,
    state: { categories, products }
  } = useContext(CategoryContext);


  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await fetchCategories();
      setLoading(false);
    }

    tryLocalSignin(navigate, '/delete_products');
    fetchData();
  }, []);

  const handleSubmit = async () => {
    setLoading(true);

    if (submitType === 'category') {
      const res = await deleteCategory(selectedCategoryId);

      if (res.error) {
        setModalText('There is a problem in deleting the Category!');
      } else {
        setModalText('Category deleted successfully');
        setSelectedCategoryId(null);
        fetchCategories();
      }
    } else if (submitType === 'product') {
      const res = await deleteProduct(selectedProductId);

      if (res.error) {
        setModalText('There is a problem in deleting the Test!');
      } else {
        setModalText('Test deleted successfully');
        setSelectedProductCategoryId(null);
        setSelectedProductId(null);
        fetchProducts(selectedProductCategoryId)
      }
    }

    setShowConfirmButton(false);
    setModalVisible(true);
    setSubmitType(null);
    setLoading(false);
  };

  const classes = useStyles();

  if (!token) {
    return null;
  }

  return (
    <div>
      <Container style={{ marginTop: '1%' }}>
        {loading && (
          <CircularProgress
            style={{
              display: 'flex',
              position: 'absolute',
              alignSelf: 'center',
              top: '50%',
              left: '50%'
            }}
          />
        )}
        <Fade timeout={1000} in={true}>
          <div>
            <Typography className={classes.title}>Delete Category</Typography>
            <Divider />
            <Grid
              className={
                isMobileOnly ? classes.mobileContainer : classes.container
              }
              container
            >
              <Grid xs={12}>
                <Typography className={classes.chooseText}>
                  Choose Category
                </Typography>
                <Select
                  placeholder="Choose Category"
                  variant="outlined"
                  className={classes.selectCategory}
                  onChange={async event => {
                    setSelectedCategoryId(event.target.value);
                  }}
                >
                  {categories.map(category => {
                    return (
                      <MenuItem value={category.id}>
                        {category.englishName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="secondary"
              className={classes.submitButton}
              onClick={() => {
                if (!selectedCategoryId) {
                  return;
                }

                setSubmitType('category');
                setModalText(
                  'Do you want to submit this? All Products belonging to the selected category will be deleted'
                );
                setShowConfirmButton(true);
                setModalVisible(true);
              }}
            >
              Submit
            </Button>
            <Typography style={{ marginTop: '3%' }} className={classes.title}>Delete Test</Typography>
            <Divider />
            <Grid
              className={
                isMobileOnly ? classes.mobileContainer : classes.container
              }
              container
            >
              <Grid item md={6} xs={12}>
                <Typography className={classes.chooseText}>
                  Choose Category
                </Typography>
                <Select
                  placeholder="Choose Category"
                  variant="outlined"
                  className={classes.selectCategory}
                  onChange={async event => {
                    setSelectedProductCategoryId(event.target.value);
                    await fetchProducts(event.target.value);
                  }}
                >
                  {categories.map(category => {
                    return (
                      <MenuItem value={category.id}>
                        {category.englishName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography className={classes.chooseText}>
                  Choose Test
                </Typography>
                <Select
                  placeholder="Choose Test"
                  variant="outlined"
                  className={classes.selectCategory}
                  onChange={async event => {
                    setSelectedProductId(event.target.value);
                  }}
                >
                  {products.map(product => {
                    return (
                      <MenuItem value={product.id}>
                        {product.englishName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="secondary"
              className={classes.submitButton}
              onClick={() => {
                if (!selectedProductCategoryId || !selectedProductId) {
                  return;
                }

                setSubmitType('product');
                setModalText(
                  'Do you want to submit this?'
                );
                setShowConfirmButton(true);
                setModalVisible(true);
              }}
            >
              Submit
            </Button>
            <CustomModal
              title="Warning!"
              content={modalText}
              visible={modalVisible}
              onCancel={() => setModalVisible(false)}
              onConfirm={handleSubmit}
              showConfirmButton={showConfirmButton}
              loading={loading}
            />
          </div>
        </Fade>
      </Container>
    </div>
  );
};

const useStyles = makeStyles({
  title: {
    fontSize: '1.6em'
  },
  container: {
    marginTop: '2%'
  },
  mobileContainer: {
    marginTop: '5%'
  },
  chooseText: {
    fontSize: '1.3em',
    marginBottom: '1%'
  },
  selectCategory: {
    width: isMobileOnly ? '85%' : '50%'
    //marginBottom: isMobileOnly ? '5%' : 0
  },
  uploadButton: {
    marginTop: '5%'
  },
  submitButton: {
    float: 'right',
    width: isMobileOnly ? '35%' : '10%',
    marginTop: isMobileOnly ? '5%' : 0,
    marginBottom: '2%'
  }
});

export default DeleteProducts;
