export const locationDetails = [{
  englishName: 'Jabal Amman Branch 1',
  arabicName: 'فرع جبل عمان 1',
  englishStName: 'Al Khaldi Street - Al Jawhar Building (56)',
  arabicStName: 'شارع الخالدي - مجمع الجوهرة (57)',
  phoneNumber: '0799397578',
  location: "https://www.google.com/maps/place/31%C2%B057'06.6%22N+35%C2%B054'05.0%22E/@31.9518417,35.8992056,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x0!8m2!3d31.9518417!4d35.9013943?hl=en&shorturl=1"
}, {
  englishName: 'Jabal Amman Branch 2',
  arabicName: 'فرع جبل عمان 2',
  englishStName: 'Zoubi & Nassar Building (29)',
  arabicStName: 'شارع الخالدي - مجمع الزعبي ونصار (29)',
  phoneNumber: '0797515453',
  location: "https://www.google.com/maps/place/31%C2%B057'13.0%22N+35%C2%B053'55.7%22E/@31.9535976,35.8966029,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x0!8m2!3d31.9535976!4d35.8987916?q=31.9535976,35.8987916&z=17&hl=en&shorturl=1"
}, {
  englishName: 'Jabal Amman Branch 3',
  arabicName: 'فرع جبل عمان 3',
  englishStName: 'Al Khaldi Street - Abu Hassan Building (81)',
  arabicStName: 'شارع الخالدي - مجمع أبو حسان (81)',
  phoneNumber: '0797900614',
  location: "https://www.google.com/maps/place/31%C2%B056'58.9%22N+35%C2%B054'19.3%22E/@31.9497044,35.9031626,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x0!8m2!3d31.9497044!4d35.9053513?q=31.9497044,35.9053513&z=17&hl=en&shorturl=1"
}, {
  englishName: 'Abdali Branch',
  arabicName: 'فرع العبدلي',
  englishStName: 'Al-Istethmar Street - DAMAC Tower',
  arabicStName: 'شارع الإستثمار - برج داماك',
  phoneNumber: '0799965751',
  location: "https://www.google.com/maps?q=31.96356201171875,35.90911865234375&z=17&hl=en"
}, {
  englishName: 'Shmeisani Branch',
  arabicName: 'فرع الشميساني',
  englishStName: 'Ibn Hayyan Street - Sami Momani Building (56)',
  arabicStName: 'شارع ابن حيان - مجمع سامي المومني (56)',
  phoneNumber: '0790454640',
  location: "https://www.google.com/maps/place/31%C2%B058'41.2%22N+35%C2%B054'07.2%22E/@31.9781062,35.8998042,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x0!8m2!3d31.9781062!4d35.9019929?q=31.9781062,35.9019929&z=17&hl=en&shorturl=1"
}, {
  englishName: 'Abdoun Branch',
  arabicName: 'فرع عبدون - الدوار الخامس',
  englishStName: 'Suleiman Hadedi Street - Jana Building (1)',
  arabicStName: 'خلف فندق الشيراتون - مجمع حنانيا (2)',
  phoneNumber: '0799159491',
  location: "https://www.google.com/maps/place/31%C2%B057'35.6%22N+35%C2%B052'44.1%22E/@31.9598923,35.8767252,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x9558d4876ee623d0!8m2!3d31.9598923!4d35.8789139?q=31.95989227294922,35.87891387939453&z=17&hl=en&shorturl=1"
}, {
  englishName: 'Al-Bayader Branch - 8th Circle',
  arabicName: 'فرع البيادر - الدوار الثامن',
  englishStName: 'Hosni Souber Street - Khamis Balbisi Building (34)',
  arabicStName: 'الشارع الرئيسي - مجمع خميس البلبيسي (34)',
  phoneNumber: '0798954765',
  location: "https://www.google.com/maps/place/Al-Bayader+St.+34,+Amman/@31.9551916,35.8422347,17z/data=!4m5!3m4!1s0x151ca1691ba2f5b7:0x50548d3d4e7f4bf4!8m2!3d31.9552232!4d35.8420613?hl=en&shorturl=1"
}, {
  englishName: 'Family Clinic Branch - Sweifieh',
  arabicName: 'فرع عيادة الاسرة - الصويفية',
  englishStName: 'Sweifieh - Princess Taghreed Street - in opposite to the Strand Hotel - Building No. 2, ground floor',
  arabicStName: 'الصويفية - شارع الأميرة تغريد - مقابل فندق ستراند - البناية رقم ٢ الطابق الارضي',
  phoneNumber: '0799302189',
  location: "https://www.google.com/maps?q=31.9560433,35.8703173&z=17&hl=en"
}]