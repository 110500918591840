import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  makeStyles,
  Typography,
  Divider,
  Container,
  Fade,
  CircularProgress,
} from '@material-ui/core';
//import { io } from 'socket.io-client';
import { isMobileOnly } from 'react-device-detect';
import OrderDetails from './components/OrderDetails';
import { Context as OrderContext } from '../context/OrderContext';
import { Context as AuthContext } from '../context/AuthContext';

const Orders = () => {
  const [loading, setLoading] = useState(false);

  const {
    state: { token },
    tryLocalSignin
  } = useContext(AuthContext);

  const {
    fetchAppointments,
    state: { appointments }
  } = useContext(OrderContext);

  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await fetchAppointments();
      setLoading(false);
    }
  
    tryLocalSignin(navigate, '/orders');
  
    fetchData();
  }, []);

  if (!token) {
    return null;
  }

  return (
    <Container style={{ marginTop: '1%' }}>
      <Fade timeout={1000} in={true}>
        <div>
          <Typography className={classes.title}>Orders</Typography>
          <Divider />
          {loading && (
            <CircularProgress
              style={{
                display: 'flex',
                position: 'absolute',
                alignSelf: 'center',
                top: '50%',
                left: '50%'
              }}
            />
          )}
          {appointments.map(appointment => {
            const { id, name, phoneNumber, address, date, time, notes, isDone } = appointment;
            const dateAndTime = new Date(`${date}`)
            const utcHour = time.split(':')[0];
            const utcMinute = time.split(':')[1];
            dateAndTime.setUTCHours(utcHour);
            dateAndTime.setUTCMinutes(utcMinute);

            return (
              <OrderDetails
                id={id}
                name={name}
                phoneNumber={phoneNumber}
                address={address}
                dateAndTime={dateAndTime}
                appointmentNotes={notes}
                isAppointmentDone={isDone}
              />
          )})}
        </div>
      </Fade>
    </Container>
  );
};

const useStyles = makeStyles({
  title: {
    fontSize: '1.6em'
  },
  accordionContainer: {
    marginTop: '2%',
    marginBottom: '2%',
    borderRadius: 15,
    width: '100%'
  },
  headerText: {
    color: 'white'
  },
  details: {
    marginBottom: isMobileOnly ? '8%' : '5%'
  },
  orderDetailsText: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    marginBottom: '2%'
  }
});

export default Orders;
