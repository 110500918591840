import React, { useContext, useEffect } from 'react';
import { Box, Container, Typography, makeStyles, Divider } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import NavBar from '../components/Navbar';
import { Context as LanguageContext } from '../context/LanguageContext';
import { Context as CategoryContext } from '../context/CategoryContext';
import { appColor } from '../common';
import { isMobileOnly } from 'react-device-detect';

const AWS_URL = 'https://smartlabs-bucket.s3.eu-central-1.amazonaws.com/';

const SearchResults = () => {
  const {
    state: { language },
  } = useContext(LanguageContext);
  const { searchProducts, state: { searchRes } } = useContext(CategoryContext);

  const props = {
    language
  };

  const location = useLocation();
  const navigate = useNavigate();
  const { searchText } = location.state;

  useEffect(() => {
    async function fetchData() {
      await searchProducts(searchText);
    }

    fetchData();
  }, [])

  const classes = useStyles(props);
  return (
    <Box>
      <NavBar />
      <Container style={{ marginTop: '5%' }}>
        <Typography className={classes.title}>
          {language === 'en' ? `Search Results for ${searchText}` : `${searchText} نتائج البحث عن`}
        </Typography>
        <Divider />
        {searchRes.map(product => <Container style={{ marginTop: '2%' }}>
          <Box
            onClick={() => {
              navigate('/product', {
                state: {
                  arabicName: product.arabicName,
                  englishName: product.englishName,
                  arabicDescription: product.arabicDescription,
                  englishDescription: product.englishDescription,
                  imageUrl: AWS_URL + product.imageUrl
                }
              })
            }}
            className={classes.buttonContainer}
          >
            <Typography className={classes.productTitle}>
              {language === 'en' ? product.englishName : product.arabicName}
            </Typography>
            <Divider className={classes.divider} />
            <Typography className={classes.productContent}>
              {language === 'en'
                ? isMobileOnly ? product.englishDescription.split('\n')[0].slice(0, 100) + '...' : product.englishDescription.split('\n')[0].slice(0, 300) + '...'
                : isMobileOnly ? '...' + product.arabicDescription.split('\n')[0].slice(0, 100) : '...' + product.arabicDescription.split('\n')[0].slice(0, 300)
              }
            </Typography>
          </Box>
        </Container>
        )}
      </Container>
    </Box>
  );
};

export const useStyles = makeStyles({
  title: {
    fontSize: '1.8em',
    textAlign: ({ language }) => (language === 'en' ? 'left' : 'right'),
    color: appColor
  },
  buttonContainer: {
    background: 'linear-gradient(to right, #1b2551, #acafaf)',
    borderRadius: 15,
    padding: isMobileOnly ? '5%' : '2%',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  productTitle: {
    fontSize: '1.5em',
    textAlign: ({ language }) => (language === 'en' ? 'left' : 'right'),
    color: 'white'
  },
  divider: {
    marginBottom: '1%',
    border: '1px solid #ffffff',
  },
  productContent: {
    fontSize: '1em',
    textAlign: ({ language }) => (language === 'en' ? 'left' : 'right'),
    color: 'gray',
    color: '#FEFEFA'
  }
})

export default SearchResults;