import axios from 'axios';
import createDataContext from './createDataContext';

const authReducer = (state, action) => {
  switch (action.type) {
    case 'error':
      return { ...state, errorMessage: action.payload };
    case 'signin':
      return { ...state, errorMessage: '', token: action.payload };
    case 'signout':
      return { ...state, token: null, errorMessage: '' };
    default:
      return state;
  }
};

async function saveItem(key, value) {
  await window.localStorage.setItem(key, value);
}

async function getItem(key) {
  let result = await window.localStorage.getItem(key);
  return result;
}

async function deleteItem(key) {
  await window.localStorage.removeItem(key);
}

// const signup = dispatch => async (username, password, navigate) => {
//   try {
//     const response = await axios.post('/api/signup', {
//       username,
//       password
//     });
//     await saveItem('smartlabs_token', response.data.token);
//     dispatch({ type: 'signin', payload: response.data.token });
//     navigate('/orders');
//     return { error: '' };
//   } catch (err) {
//     dispatch({ type: 'error', payload: 'Something went wrong with sign up' });
//     return { error: 'Something went wrong with sign up' };
//   }
// };

const fetchCustomers = dispatch => async () => {
  try {
    const response = await axios.get('/api/customer');
    return { error: '', customers: response.data.customers }
  } catch (err) {
    return { error: 'Something went wrong while fetching customers' };
  }
}

const signin = dispatch => async (username, password, navigate) => {
  try {
    const response = await axios.post('/api/signin', {
      username,
      password
    });
    await saveItem('smartlabs_token', response.data.token);
    dispatch({ type: 'signin', payload: response.data.token });
    navigate('/orders');
    return { error: '' };
  } catch (err) {
    dispatch({ type: 'error', payload: 'Something went wrong with sign in' });
    return { error: 'Something went wrong with sign in' };
  }
};

const tryLocalSignin = dispatch => async (navigate, successRoute) => {
  const token = await getItem('smartlabs_token');
  if (token) {
    dispatch({ type: 'signin', payload: token });
    navigate(successRoute);
  } else {
    navigate('/adminarea');
  }
};

const signout = dispatch => async navigate => {
  await deleteItem('smartlabs_token');
  dispatch({ type: 'signout' });
  navigate('/adminarea');
};

export const { Context, Provider } = createDataContext(
  authReducer,
  { signin, signout, tryLocalSignin, fetchCustomers }, // signup
  {
    token: window.localStorage.getItem('smartlabs_token')
      ? window.localStorage.getItem('smartlabs_token')
      : null,
    errorMessage: ''
  }
);
