import axios from 'axios';
import createDataContext from './createDataContext';

const notificationsReducer = (state, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const sendNotification = dispatch => async (notificationTitle, notificationText) => {
  const res = await axios.get('/api/notifications');

  const pushTokens = res.data.map(tokenObj => tokenObj.token);

  try {
    await axios.post('/api/notify', {
      title: notificationTitle,
      body: notificationText,
      pushTokens
    });
    return { error: '' };
  } catch {
    return { error: 'Problem occurred while sending notification' };
  }
}

export const { Context, Provider } = createDataContext(
  notificationsReducer,
  {
    sendNotification
  },
  {}
);
