import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    makeStyles,
    TextField,
    Container,
    Button,
    Fade,
    Box,
    CircularProgress
} from '@material-ui/core';
import CustomModal from './components/CustomModal';
import { Context as AuthContext } from '../context/AuthContext';
import { isMobileOnly } from 'react-device-detect';
import { appColor } from '../common';

const height = window.innerHeight;
const width = window.innerWidth;

const AdminLogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [modalText, setModalText] = useState('');
    const [dialogVisible, setDialogVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const classes = useStyles();
    const navigate = useNavigate();


    const { signin, tryLocalSignin } = useContext(AuthContext);

    const signInUser = async () => {
        if (!username || !password) {
            setDialogVisible(true);
            setModalText('Missing username or password!');
            return;
        }
        setModalLoading(true);
        const res = await signin(username, password, navigate);
        if (res.error) {
            setModalLoading(false);
            setDialogVisible(true);
            setModalText('Invalid username or password! Please try again');
        }
    };

    useEffect(() => {
        async function localLogin() {
            setLoading(true);
            await tryLocalSignin(navigate, '/orders');
            setLoading(false);
        }

        localLogin();
    }, []);

    return (
        <Container className={classes.container}>
            {loading && (
                <CircularProgress
                    style={{
                        position: 'absolute',
                        alignSelf: 'center',
                        marginTop: '16%'
                    }}
                />
            )}
            <Fade timeout={1000} in={true}>
                <div>
                    <Box className={classes.box}>
                        <img
                            src={require('../images/logo.png')}
                            alt="logo"
                            className={isMobileOnly ? classes.logoMobile : classes.logo}
                        />
                        <TextField
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                            className={
                                isMobileOnly ? classes.mobileTextField : classes.textField
                            }
                            id="outlined-basic"
                            placeholder="Username"
                            variant="outlined"
                            color="primary"
                        />
                        <TextField
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            className={
                                isMobileOnly ? classes.mobileTextField : classes.textField
                            }
                            id="outlined-basic"
                            placeholder="Passowrd"
                            variant="outlined"
                            color="primary"
                            type="password"
                            onKeyPress={ev => {
                                if (ev.key === 'Enter') {
                                    signInUser();
                                    ev.preventDefault();
                                }
                            }}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            className={isMobileOnly ? classes.mobileButton : classes.button}
                            onClick={() => signInUser()}
                        >
                            SIGN IN
                        </Button>
                    </Box>
                </div>
            </Fade>
            <CustomModal
                title="Warning!"
                content={modalText}
                visible={dialogVisible}
                onCancel={() => setDialogVisible(false)}
                showConfirmButton={false}
                loading={modalLoading}
            />
        </Container>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        height: height,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    box: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        boxShadow: theme.shadows[3],
        height: height / 1.2,
        width: width / 1.05
    },
    logo: {
        alignSelf: 'center',
        objectFit: 'contain',
        height: height / 8
    },
    logoMobile: {
        alignSelf: 'center',
        width: width / 1.5,
        objectFit: 'contain',
        height: height / 5
    },
    textField: {
        width: '35%',
        marginTop: '2%'
    },
    mobileTextField: {
        width: '80%',
        marginTop: '5%'
    },
    button: {
        width: '30%',
        marginTop: '2%',
        backgroundColor: appColor,
        color: 'white',
        '&:hover': {
            backgroundColor: appColor,
            color: 'white'
        }
    },
    mobileButton: {
        width: '50%',
        marginTop: '5%',
        backgroundColor: appColor,
        color: 'white',
        '&:hover': {
            backgroundColor: appColor,
            color: 'white'
        }
    }
}));

export default AdminLogin;
