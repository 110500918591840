import axios from 'axios';
import createDataContext from './createDataContext';
import { uploadNewImages, deleteImages } from './commons';

const popupReducer = (state, action) => {
  switch (action.type) {
    case 'upload_popup':
      return { ...state, errorMessage: '' };
    case 'fetch_popup':
      return { ...state, errorMessage: '', popup: action.payload };
    case 'delete_popup':
      return { ...state, errorMessage: '' };
    case 'reset_popup':
      return { ...state, errorMessage: '', popup: [] };
    case 'error':
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};

const uploadPopup =
  dispatch => async (image) => {
    /*Image Config*/
    let imgConfig;
    try {
      imgConfig = await axios.get('/api/image/config', {
        params: {
          path: `popup`
        }
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: 'error',
        payload: 'Something went wrong while uploading popup image config'
      });
      return { error: 'Error in uploading popup image config' };
    }

    /*Upload Image to AWS*/
    try {
      await axios.put(imgConfig.data.url, image, {
        headers: {
          'Content-Type': image.type
        }
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: 'error',
        payload: 'Something went wrong while uploading popup image to AWS'
      });
      return { error: 'Error in Uploading popup image to AWS' };
    }

    try {
      await axios.post('/api/popup', {
        imageUrl: imgConfig.data.key,
      });
      dispatch({ type: 'upload_popup' });
      return { error: '' };
    } catch {
      dispatch({
        type: 'error',
        payload: 'Something went wrong while uploading popup to the database'
      });
      return { error: 'Error in Uploading popup to the database' };
    }
  };

const fetchPopup = dispatch => async () => {
  try {
    const res = await axios.get('/api/popup');

    dispatch({ type: 'fetch_popup', payload: res.data.reverse() });
    return { error: '' };
  } catch (err) {
    dispatch({
      type: 'error',
      payload: 'Something went wrong while fetching popup'
    });
    return { error: 'Error in Fetching popup' };
  }
};

const deletePopup = dispatch => async (selectedPopupId, navigate) => {
  try {
    const fetchingRes = await axios.get('/api/popup', { params: { selectedPopupId } });
    const imageUrl = fetchingRes.data[0].imageUrl;

    await axios.delete('/api/popup', {
      data: { id: selectedPopupId }
    });

    deleteImages([imageUrl]);

    dispatch({ type: 'delete_popup' });
    navigate(0);
    return { error: '' };
  } catch (err) {
    dispatch({
      type: 'error',
      payload: 'Something went wrong while deleting the popup'
    });
    return { error: 'Error in deleting the popup' };
  }
};

const resetPopupState = dispatch => () => {
  dispatch({ type: 'reset_popup' });
};

export const { Context, Provider } = createDataContext(
  popupReducer,
  {
    fetchPopup,
    uploadPopup,
    deletePopup,
    resetPopupState
  },
  { popup: [], errorMessage: '' }
);
